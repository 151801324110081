import Faq from "../../components/faq";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import prieHero from "./assets/pricehero.webp";
import pattern from "./assets/Background pattern decorative.png";
import { Link } from "react-router-dom";
import right from "./assets/arrow-right.svg";
import { useGTM } from "../../utils/useGTM";

const Tutorials = () => {
  // useGTM();
  return (
    <>
      <Navbar />
      <div className=" px-4 md:px-8 w-full mt-28">
        <div
          style={{
            backgroundImage: `url(${prieHero})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="  h-[329px] w-full flex p-6 flex-col items-center justify-center relative rounded-[20px]"
        >
          <img src={pattern} className=" absolute left-0 -top-[200px]" alt="" />
          <Link
            to="/signUp"
            className=" mt-8 z-30 md:mt-12 bg-[#db383b] rounded-[50px] py-3 px-6 flex flex-row items-center space-x-1"
          >
            <p className=" text-white font-BricolageGrotesque text-sm font-medium">
              Try For Free
            </p>
            <img src={right} alt="" />
          </Link>
          <h1 className=" font-BricolageGrotesque capitalize font-semibold text-4xl md:text-[75px] md:leading-[97px] text-center text-white">
            Dorascribe Tutorials
          </h1>
          <p className=" mt-3 font-Inter text-[#f5f5f58b] text-base md:text-xl font-normal text-center lg:w-[800px]">
            Master AI-powered documentation with Dorascribe. Explore
            step-by-step tutorials designed to enhance your workflow.
          </p>
        </div>

        <div className="px-4 md:px-14 w-full py-8 md:py-16 flex justify-center items-center flex-col">
          <p className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-black">
            Get Started with Dorascribe: Video Walkthroughs
          </p>
          <p className=" text-center font-Inter text-base md:text-lg font-normal mt-3 text-[#282D2D] lg:w-[800px]">
            New to Dorascribe? These video tutorials cover everything you need
            to begin, from signing up to using key transcription tools. See how
            Dorascribe can optimize your documentation process, helping you
            reduce admin time and focus on patient care.
          </p>

          <div className=" mt-16 grid  grid-cols-1 md:grid-cols-3 gap-8 w-full">
            <div className=" w-full h-[300px] bg-[#f1f1f1] rounded-[20px]">
              <iframe
                className=" w-full h-full rounded-[20px]"
                src="https://www.youtube.com/embed/jRnNgcmDfHY"
              ></iframe>
              <h3 className=" mt-3 font-Inter text-base ">
                Set up your Dorascribe account to get started with AI-powered
                transcription.
              </h3>
            </div>

            <div className=" w-full h-[300px] bg-[#f1f1f1] rounded-[20px]">
              <iframe
                className=" w-full h-full rounded-[20px]"
                src="https://www.youtube.com/embed/DoDCFIsMTNg"
              ></iframe>
              <h3 className=" mt-3 font-Inter text-base ">
                Transcribe patient conversations into medical notes accurately
                and efficiently.
              </h3>
            </div>

            <div className=" w-full h-[300px] bg-[#f1f1f1] rounded-[20px]">
              <iframe
                className=" w-full h-full rounded-[20px]"
                src="https://www.youtube.com/embed/rx6SbZo5Zzk"
              ></iframe>
              <h3 className=" mt-3 font-Inter text-base ">
                Manage created medical Transcriptions in Dorascribe
              </h3>
            </div>
          </div>
        </div>
      </div>
      <Faq />
      <div className=" flex justify-center items-center mb-12">
        <Link
          className=" text-center px-5 py-3 font-BricolageGrotesque text-base rounded-[40px] font-semibold shadow hover:shadow-[#007272] transition-all bg-[#00AAAA] text-white"
          to="/faqs"
        >
          Get more answers on the FAQ page
        </Link>
      </div>

      <Footer />
    </>
  );
};

export default Tutorials;
