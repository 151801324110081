import { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActivePageContext } from '../contexts/ActivePageContext';
import {
	handleAllNotes,
	handleAllDeletedNotes,
	handleDeleteNoteById,
	handleGetRestoreNote,
	handleDeleteNotePermanently,
} from '../../../controllers/NoteController';
import { getOS } from '../../../utils';
import useLocalStorageListener from '../hooks/useLocalStorageListener';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { formatDate } from '../../../utils/dateUtils';
import UserFormModal from './UserFormModal';
import DropdownMenu from './DropdownMenu';
import BackToTop from './BackToTop';

import recent from './assets/recent.svg';
import trash from './assets/trash-03.svg';
import plus from './assets/plus.svg';
import search from './assets/search-normal.svg';
import dots from './assets/DotsThreeOutlineVertical.svg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const AllNotesMobile = () => {
	const [activeTab, setActiveTab] = useState('recent');
	const [startRecording, setStartRecording] = useState(false);
	const { setActivePage } = useContext(ActivePageContext);
	const [notes, setNotes] = useState([]);
	const [deletedNotes, setDeletedNotes] = useState([]);
	const [dropdownVisible, setDropdownVisible] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const dropdownRef = useRef(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [os, setOS] = useState('');
	const [patientInfo, setPatientInfo] = useLocalStorageListener('patient_info', null);
	const [noteLoader, setNoteLoader] = useLocalStorageListener('note_load', null);
	const Navigate = useNavigate();

	useEffect(() => {
		const fetchNotes = async () => {
			const data = await handleAllNotes();
			if (data) {
				setNotes(data);
				setIsLoading(false);
			}
		};

		const fetchDeletedNotes = async () => {
			const response = await handleAllDeletedNotes();
			if (response) {
				setDeletedNotes(response.data.data);
				setIsLoading(false);
			}
		};

		fetchNotes();
		fetchDeletedNotes();
	}, []);

	useEffect(() => {
		const storedPatientInfo = localStorage.getItem('patient_info');
		if (storedPatientInfo) {
			setPatientInfo(JSON.parse(storedPatientInfo));
		}
	}, [setPatientInfo]);

	useEffect(() => {
		const userOS = getOS();
		setOS(userOS);
	}, []);

	useEffect(() => {
		if (os === 'iOS') {
			const storedNoteId = localStorage.getItem('note_id');
			const dataLoader = {
				note_id: JSON.parse(storedNoteId),
				is_note: false,
			};

			setTimeout(() => {
				setNoteLoader(dataLoader);
			}, 2500);
		} else {
			const isNoteLoading = localStorage.getItem('note_load');
			if (isNoteLoading) setNoteLoader(JSON.parse(isNoteLoading));
		}
	}, [os, setNoteLoader]);

	useEffect(() => {
		if (patientInfo) {
			const fetchNotes = async () => {
				const data = await handleAllNotes();
				if (data) {
					setNotes(data);
				}
			};

			fetchNotes();
		}
	}, [patientInfo]);

	const handlePatientCreated = (data) => {
		if (data.res) {
			const fetchNotes = async () => {
				const data = await handleAllNotes();
				if (data) {
					setNotes(data);
				}
			};

			fetchNotes();
		}
	};

	const handleStartRecording = () => {
		setStartRecording(true);
		setActivePage('Home');
		Navigate('/dashboard');
	};

	const handleClick = (page) => {
		setStartRecording(false);
		setActivePage(page);
	};

	const toggleDropdown = (noteId) => {
		setDropdownVisible((prev) => (prev === noteId ? null : noteId));
	};

	const handleOpenNote = (noteId) => {
		setDropdownVisible(null);
		localStorage.setItem('note_id', JSON.stringify(noteId));
		Navigate('/dashboard');
		setActivePage('Transcribed');
	};

	const handleEditNote = (noteId) => {
		setDropdownVisible(null);
		localStorage.setItem('note_id', JSON.stringify(noteId));
		Navigate('/transcription');
		window.location.reload();
		// setActivePage('Transcribe');
	};

	const handleDeleteNote = async (noteId) => {
		await handleDeleteNoteById(noteId);
		const fetchNotes = async () => {
			const data = await handleAllNotes();
			if (data) {
				setNotes(data);
			}
		};

		const fetchDeletedNotes = async () => {
			const response = await handleAllDeletedNotes();
			if (response) {
				setDeletedNotes(response.data.data);
			}
		};

		fetchNotes();
		fetchDeletedNotes();
		setDropdownVisible(null);
	};

	const handleRestoreNote = async (noteId) => {
		await handleGetRestoreNote(noteId);
		const fetchNotes = async () => {
			const data = await handleAllNotes();
			if (data) {
				setNotes(data);
			}
		};

		const fetchDeletedNotes = async () => {
			const response = await handleAllDeletedNotes();
			if (response) {
				setDeletedNotes(response.data.data);
			}
		};

		fetchNotes();
		fetchDeletedNotes();
		setDropdownVisible(null);
	};

	const handleDeleteFromTrash = async (noteId) => {
		await handleDeleteNotePermanently(noteId);
		const fetchDeletedNotes = async () => {
			const response = await handleAllDeletedNotes();
			if (response) {
				setDeletedNotes(response.data.data);
			}
		};

		fetchDeletedNotes();
		setDropdownVisible(null);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownVisible(null);
		}
	};

	useEffect(() => {
		if (dropdownVisible !== null) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownVisible]);

	const handleSearchChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const filteredNotes = notes.filter(
		(note) =>
			(note.patient_name && note.patient_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
			(note.created_at && formatDate(note.created_at).toLowerCase().includes(searchQuery.toLowerCase()))
	);

	const renderNote = (note) => (
		<div key={note.id} className='relative'>
			<button className='w-full flex py-3 px-6 rounded-[10px] justify-between border-[#EAEBF0] border items-center'>
				<span className='flex flex-col items-start'>
					{noteLoader?.is_note && noteLoader?.note_id === note.id ? (
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
							<Skeleton height={15} width={75} count={1} />
							<Skeleton height={15} width={150} count={1} />
						</div>
					) : (
						<div onClick={activeTab === 'recent' ? () => handleOpenNote(note.id) : null}>
							<p className='text-[#3e2121] font-BricolageGrotesque font-semibold text-sm text-left'>
								{note.patient_name ? note.patient_name : 'Not set'}
							</p>
							<p className='text-[#282D2D] font-Inter font-normal text-sm'>{formatDate(note.created_at)}</p>
						</div>
					)}
				</span>
				{noteLoader?.is_note && noteLoader?.note_id === note.id ? (
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
						<Skeleton height={30} width={15} count={1} />
					</div>
				) : (
					<img src={dots} alt='Option' onClick={() => toggleDropdown(note.id)} />
				)}
			</button>
			{dropdownVisible === note.id && (
				<div ref={dropdownRef}>
					{activeTab === 'recent' ? (
						<DropdownMenu
							options={[
								{
									label: 'Open',
									onClick: () => handleOpenNote(note.id),
								},
								{
									label: 'Edit',
									onClick: () => handleEditNote(note.id),
								},
								{
									label: 'Delete',
									onClick: () => handleDeleteNote(note.id),
									textColor: 'text-[#E2341D]',
									fontWeight: 'font-semibold',
								},
							]}
						/>
					) : (
						<DropdownMenu
							options={[
								{
									label: 'Restore',
									onClick: () => handleRestoreNote(note.id),
								},
								{
									label: 'Delete',
									onClick: () => handleDeleteFromTrash(note.id),
									textColor: 'text-[#E2341D]',
									fontWeight: 'font-semibold',
								},
							]}
						/>
					)}
				</div>
			)}
		</div>
	);

	return (
		<>
			<UserFormModal
				isVisible={startRecording}
				onClose={() => setStartRecording(false)}
				handleClick={handleClick}
				onPatientCreated={handlePatientCreated}
			/>

			<div className='absolute top-[70px] w-full mx-auto h-auto bg-white z-[99] block md:hidden'>
				<div className='w-[92%] mx-auto mb-4'>
					<div className='w-full flex items-center justify-between'>
						<div
							onClick={() => setActiveTab('recent')}
							className={`mt-4 items-center flex w-[50%] space-x-4 justify-center py-3 border-b-[3px] ${
								activeTab === 'recent' ? 'border-[#00aaaa]' : 'border-[#f5f5f5]'
							}`}
						>
							<div className='flex'>
								<img src={recent} alt='Recent' />
								<p className='font-Inter font-semibold text-sm text-black ml-2'>Recent Notes</p>
							</div>
							<button className='bg-[#E1EAEA] rounded-[5px] py-[2px] px-2 text-center font-Inter font-semibold text-sm text-[#00aaaa]'>
								{notes.length}
							</button>
						</div>
						<div
							onClick={() => setActiveTab('trash')}
							className={`mt-4 items-center flex w-[50%] space-x-4 justify-center py-3 border-b-[3px] ${
								activeTab === 'trash' ? 'border-[#00aaaa]' : 'border-[#f5f5f5]'
							}`}
						>
							<span className='flex items-center space-x-3'>
								<img src={trash} alt='Trash' />
								<p className='text-sm font-Inter font-semibold text-black'>Trash</p>
							</span>
							<button className='py-[2px] px-2 rounded-[5px] bg-[#EEE4E3] text-center text-sm text-[#E2341D] font-Inter font-semibold'>
								{deletedNotes.length}
							</button>
						</div>
					</div>

					{activeTab === 'recent' && (
						<>
							<button
								onClick={handleStartRecording}
								className='justify-center py-3 px-4 bg-[#F5F5F5] rounded-[48px] mt-6 w-full flex items-center space-x-3'
							>
								<img src={plus} className='w-[24px] h-[24px]' alt='Plus' />
								<p className='text-[#0D023E] font-BricolageGrotesque font-semibold text-sm'>Start Consult</p>
							</button>

							<span className='relative w-full'>
								<img src={search} className='absolute top-[6px] left-2.5' alt='Search' />
								<input
									type='search'
									value={searchQuery}
									onChange={handleSearchChange}
									className='w-full rounded-[48px] py-2.5 pr-2.5 pl-8 bg-white border border-[#EAEBF0] mt-4 font-Inter text-sm font-normal text-black placeholder:text-black'
									placeholder='Search...'
									data-tooltip-id='search-tooltip'
									data-tooltip-content='You can search by patient name or date'
								/>
								<Tooltip id='search-tooltip' place='top-start' variant='dark' />
							</span>
						</>
					)}

					<div className='mt-3 w-full space-y-2'>
						<SkeletonTheme color='#202020' highlightColor='#aaa'>
							{isLoading ? (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Skeleton height={25} count={5} />
								</div>
							) : (
								(activeTab === 'recent' ? filteredNotes : deletedNotes).map((note) => renderNote(note))
							)}
						</SkeletonTheme>

						{activeTab === 'trash' && deletedNotes && deletedNotes.length === 0 && (
							<div className='border rounded-2xl p-2 text-center'>
								<img src={trash} className='d-block mx-auto mb-2' alt='Trash' />
								<p>
									View deleted notes here.
									<br /> Restore them within 14 days.
								</p>
							</div>
						)}
					</div>

					{activeTab === 'recent' && <p className='text-center text-sm my-3 md:hidden'>End of notes</p>}
				</div>
			</div>

			<BackToTop />
		</>
	);
};

export default AllNotesMobile;



