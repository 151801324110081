import { useState, useEffect } from 'react';
import axios from 'axios';
import close from './assets/close cross.svg';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import './assets/MarkdownComponent.css';

const PatientInfoModal = ({ onClosePatientInfoModal }) => {
	const [patientInformation, setPatientInformation] = useState(null);
	const [patientDocument, setPatientDocument] = useState(null);
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

	useEffect(() => {
		// Fetch Data Policy Information content on mount
		axios
			.get(`${API_BASE_URL}api/v1/patient-information`)
			.then((response) => {
				const result = response.data;
				const patientInfo = DOMPurify.sanitize(result.content);
				setPatientInformation(patientInfo);
				setPatientDocument(result.document);
			})
			.catch((error) => {
				console.error('Error fetching Data Policy Information:', error);
			});
	}, []);

	// Function to open the PDF in a new tab
	const handleDownload = () => {
		window.open(patientDocument, '_blank');
		onClosePatientInfoModal();
	};

	return (
		<>
			<div className='w-full md:w-full h-full bg-[#1212128d] z-[99999] fixed top-0 left-0 px-3 md:px-6 flex justify-center items-center'>
				<div className='w-full flex justify-center items-center'>
					<div className='bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-[600px] md:h-auto p-2 md:p-6 rounded-[15px] w-full md:w-[800px] lg:w-[1000px]'>
						<div className='w-full h-full overflow-y-auto bg-white'>
							<span className='flex items-center justify-between'>
								<p className='font-Inter font-semibold text-xl text-[#272D37]'>Data Policy Information</p>
								<img src={close} className='cursor-pointer' onClick={onClosePatientInfoModal} alt='' />
							</span>
							<div className='mt-6'>
								{patientInformation ? (
									<>
										<div className='markdown-container px-2 mt-4'>
											<ReactMarkdown
												children={patientInformation ? patientInformation : 'Data Policy Information not found'}
												remarkPlugins={[remarkGfm]}
												rehypePlugins={[rehypeRaw]}
												components={{
													strong: ({ node, ...props }) => <strong className='bold-text' {...props} />,
													p: ({ node, ...props }) => <p className='paragraph-text' {...props} />,
												}}
											/>
										</div>

										<button
											className='w-auto flex items-center bg-[#00AAAA] rounded-[30px] text-white font-Inter font-semibold text-base justify-center text-center py-3 px-6 my-6 mx-auto'
											onClick={handleDownload}
										>
											Download Data Policy Information
										</button>
									</>
								) : (
									<p className='text-lg'>Loading data policy information...</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PatientInfoModal;
