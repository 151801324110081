/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

const LanguageSelector = ({ onLanguageChange, defaultLanguage = 'en-US', service = 'azure' }) => {
	const deepgramSupportedLanguages = [
		{ code: 'en-US', name: 'English (US)' },
		{ code: 'en-GB', name: 'English (UK)' },
		{ code: 'es', name: 'Spanish' },
		{ code: 'fr', name: 'French' },
		{ code: 'it', name: 'Italian' },
		{ code: 'de', name: 'German' },
		{ code: 'pt', name: 'Portuguese' },
		{ code: 'ru', name: 'Russian' },
		{ code: 'zh', name: 'Chinese' },
		{ code: 'ja', name: 'Japanese' },
		{ code: 'ko', name: 'Korean' },
	];

	const assemblyAISupportedLanguages = [
		{ code: 'en', name: 'English' },
		{ code: 'es', name: 'Spanish' },
		{ code: 'fr', name: 'French' },
		{ code: 'de', name: 'German' },
		{ code: 'it', name: 'Italian' },
		{ code: 'pt', name: 'Portuguese' },
		{ code: 'hi', name: 'Hindi' },
		{ code: 'ar', name: 'Arabic' },
		{ code: 'zh', name: 'Mandarin Chinese' },
		{ code: 'ja', name: 'Japanese' },
		{ code: 'ko', name: 'Korean' },
		{ code: 'ru', name: 'Russian' },
	];

	const azureSupportedLanguages = [
		{ code: 'ar-EG', name: 'Arabic (Egypt)' },
		{ code: 'de-DE', name: 'German (Germany)' },
		{ code: 'en-US', name: 'English (United States)' },
		{ code: 'es-ES', name: 'Spanish (Spain)' },
		{ code: 'fr-FR', name: 'French (France)' },
		{ code: 'it-IT', name: 'Italian (Italy)' },
		{ code: 'ja-JP', name: 'Japanese (Japan)' },
		{ code: 'ko-KR', name: 'Korean (Korea)' },
		{ code: 'pt-BR', name: 'Portuguese (Brazil)' },
		{ code: 'zh-CN', name: 'Chinese (Mandarin, Simplified)' },
	];

	const [languages, setLanguages] = useState([]);

	// Function to get languages based on the service
	const getLanguages = (service) => {
		switch (service && service.toLowerCase()) {
			case 'aai':
				return assemblyAISupportedLanguages;
			case 'dai':
				return deepgramSupportedLanguages;
			default:
				return azureSupportedLanguages;
		}
	};

	// Update languages when the service prop changes
	useEffect(() => {
		setLanguages(getLanguages(service));
	}, [service]);

	const handleChange = (event) => {
		const selectedCode = event.target.value;
		const selectedLanguage = languages.find((lang) => lang.code === selectedCode);

		if (selectedLanguage) {
			const codeName = { code: selectedCode, name: selectedLanguage.name };
			onLanguageChange(codeName);
		}
	};

	return (
		<>
			<select
				id='language-select'
				value={defaultLanguage}
				onChange={handleChange}
				className='mt-[6px] border border-[#DAE0E6] font-Inter text-sm font-normal rounded-[20px]  px-5 py-2.5'
			>
				<option value='' disabled>
					Select language
				</option>
				{languages.map((lang) => (
					<option key={lang.code} value={lang.code}>
						{lang.name}
					</option>
				))}
			</select>
		</>
	);
};

export default LanguageSelector;
