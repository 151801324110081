import Navbar from "../../components/navbar";
import heroImg from "./assets/heroimg.webp";
import right from "./assets/arrow-right.svg";
import pattern from "./assets/Background pattern decorative.png";
import star from "./assets/star-06.svg";
import caser from "./assets/case.png.png";
import feat1 from "./assets/feat1.svg";
import feat2 from "./assets/feat2.svg";
import feat3 from "./assets/feat3.svg";
import hippa from "./assets/hipaa.svg";
import choice1 from "./assets/choice1.svg";
import choice2 from "./assets/choice2.svg";
import choice3 from "./assets/choice3.svg";
import record from "./assets/record.svg";
import transcribe from "./assets/transcribe.svg";
import save from "./assets/save.svg";
import Pricing from "../../components/pricing";
import Faq from "../../components/faq";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";

const Landing = () => {
  const feat = [
    {
      name: "AI-Driven Documentation",
      about:
        "Dorascribe's AI works discreetly on your browser, allowing you to focus on the natural patient experience..",
      image: feat1,
    },
    {
      name: "Workflow Automation",
      about:
        "We simplify your workflow and minimize administrative tasks, allowing you to prioritize patient care.",
      image: feat2,
    },
  ];

  const works = [
    {
      name: "Record",
      icon: record,
      info: "Easily capture your medical dictations using your preferred device – phone, recorder, or computer.",
    },
    {
      name: "Transcribe",
      icon: transcribe,
      info: "Securely convert your audio with Dorascribe, where our AI-powered engine will quickly and accurately convert them into text.",
    },
    {
      name: "Save",
      icon: save,
      info: "Review, edit, and seamlessly save your transcribed document in your preferred format, and integrated into your EHR system.",
    },
  ];
  return (
    <>
      <Navbar />
      {/* Hero  */}
      <div className=" px-4 md:px-8 w-full mt-28">
        <div
          style={{
            backgroundImage: `url(${heroImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className=" relative w-full h-[720px] md:h-[774px] rounded-[20px] flex justify- items-center flex-col p-6 md:p-8"
        >
          <img
            src={pattern}
            className=" absolute -top-[200px] left-0 w-full h-full"
            alt=""
          />

          <Link
            to="/signUp"
            className=" mt-8 z-30 md:mt-12 bg-[#db383b] rounded-[50px] py-3 px-6 flex flex-row items-center space-x-1"
          >
            <p className=" text-white font-BricolageGrotesque text-sm font-medium">
              Try For Free
            </p>
            <img src={right} alt="" />
          </Link>
          <h1 className=" mt-6 font-BricolageGrotesque capitalize font-semibold text-4xl md:text-6xl lg:text-[75px] lg:leading-[97px] text-center text-white">
            Medical AI speech to text transcription
          </h1>
          <p className=" mt-3 font-Inter text-[#f5f5f58b] text-base md:text-xl font-normal text-center lg:w-[800px]">
            Dorascribe uses AI-powered speech-to-text transcription tailored for
            healthcare to convert clinical consultations into EMR text
            affordably and efficiently
          </p>
          {/* <button className=" mt-6 px-8 py-[10px] bg-[#00aaaa] rounded-[50px] flex space-x-1 items-center">
            <img src={star} alt="" />
            <p className=" text-white font-BricolageGrotesque text-sm font-medium">
              Try For Free
            </p>
          </button> */}

          <img src={caser} className=" mt-[80px] " alt="" />
        </div>
      </div>
      {/* Features */}
      <div className=" px-4 md:px-14 w-full py-8 md:py-16 md:mt-[150px] lg:mt-[520px]">
        <h2 className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-black">
          What does our Medical Scribe offer
        </h2>
        <p className=" text-center font-Inter text-base md:text-lg font-normal mt-3 text-[#282D2D]">
          Here's what makes our AI-powered medical transcription platform unique
        </p>
        <div className=" w-full mt-8 md:mt-16">
          <div className=" grid grid-cols-1 lg:grid-cols-2 gap-6">
            {feat.map((item, index) => (
              <div
                key={index}
                className=" w-full h-[500px] md:h-[400px] bg-[#F5F5F5] rounded-[20px] p-8 p-md:[50px] relative"
              >
                <p className=" text-xl md:text-[32px] md:leading-[38px] font-BricolageGrotesque font-semibold md:w-[250px] text-black">
                  {item.name}
                </p>
                <p className=" mt-3 text-[#282D2D] font-Inter font-normal text-base md:text-base md:w-[250px]">
                  {item.about}
                </p>
                <img
                  src={item.image}
                  className=" absolute right-8 bottom-8"
                  alt={item.name}
                />
              </div>
            ))}
          </div>
          <div className=" w-full h-[560px] lg:h-[400px] bg-[#F5F5F5] rounded-[20px] p-8 md:p-[50px] flex flex-col lg:flex-row justify-between items-center mt-6 relative">
            <div>
              <p className=" text-xl md:text-[32px] md:leading-[38px] font-BricolageGrotesque font-semibold md:w-[375px] text-black">
                Fast & Accurate
              </p>
              <p className=" mt-3 text-[#282D2D] font-Inter font-normal text-sm md:text-base md:w-[600px]">
                Our speech-to-text transcribes complex medical terminology
                swiftly for efficient clinical documentation.
              </p>
            </div>
            <img
              src={feat3}
              alt=""
              className=" absolute lg:static right-8 bottom-8 "
            />
          </div>
          <Link to="/tutorials">
            <button className="px-5 py-3 capitalize block mt-6 mx-auto font-BricolageGrotesque text-base rounded-[40px] font-semibold shadow hover:shadow-[#007272] transition-all bg-[#00AAAA] text-white">
              Check our tutorials
            </button>
          </Link>
        </div>
      </div>
      {/* Our Pricing */}
      <Pricing />
      {/* Hipaa */}
      <div className=" bg-[#00AAAA] w-full py-12 md:py-16">
        <div className=" w-full flex flex-col lg:flex-row md:space-x-16 items-center px-4 md:px-14">
          <img
            src={hippa}
            className=" w-[50%] md:w-auto"
            alt="Dorascribe is HIPAA compliant"
          />
          <div className="">
            <h2 className=" font-BricolageGrotesque mt-3 lg:mt-0 text-white font-semibold text-2xl md:text-[40px]">
              Dorascribe is HIPAA compliant
            </h2>
            <p className=" font-Inter mt-3 text-base md:text-lg font-normal text-[#FFFFFFCC]">
              Dorascribe prioritizes the confidentiality and integrity of your
              medical data, adhering to the highest security standards and HIPAA
              regulations. We use robust encryption, de-identification measures,
              and Google authentication to protect patient privacy and ensure
              data remains anonymous.
            </p>
          </div>
        </div>
      </div>
      {/* why choose us  */}
      <div className="md:px-14 px-4 w-full py-8 md:py-16 flex justify-center items-center flex-col">
        <h2 className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-black">
          Why Choose Us
        </h2>
        <p className=" text-center font-Inter text-base md:text-lg font-normal mt-3 text-[#282D2D] lg:w-[800px]">
          Dorascribe leverages AI to offer personalized, fully customizable
          clinical documentation. Discover how our AI platform helps doctors,
          hospitals, and health systems.
        </p>
        {/* choice 1 */}
        <div className=" w-full flex flex-col-reverse md:flex-row items-center justify-between mt-8 md:mt-16">
          <div className=" w-full md:w-[48%]">
            <h2 className=" text-[#272D37] mt-4 md:mt-0 font-BricolageGrotesque font-semibold text-xl md:text-[32px] md:leading-[41px]">
              Scalable Solutions for
              <span className=" md:block"> Practices of All Sizes</span>
            </h2>
            <p className=" text-[#5F6D7E] font-Inter text-sm md:text-base font-normal mt-4">
              Dorascribe offers a range of subscription plans designed to
              accommodate practices of all sizes. Whether you're a solo
              practitioner, a small clinic, or a large hospital system, we have
              a solution that fits your budget and workload. You can easily
              scale up or down as your needs change, ensuring you always have
              the right amount of transcription support.
            </p>
            <a href="https://blog.dorascribe.ai">
              <button className=" mt-6 md:mt-10 bg-[#00aaaa] rounded-[50px] py-3 px-[18px] font-BricolageGrotesque text-sm md:text-base font-normal text-white shadow shadow-[#1018280A]">
                Start now
              </button>
            </a>
          </div>
          <div className=" w-full md:w-[48%]">
            <img src={choice1} alt="" />
          </div>
        </div>
      </div>
      {/* choice 2 */}
      <div className=" bg-[#00AAAA] py-8 md:py-16">
        <div className=" w-full flex flex-col md:flex-row items-center px-4 md:px-14 justify-between">
          <div className=" w-full md:w-[48%]">
            <img src={choice2} alt="" />
          </div>
          <div className=" w-full md:w-[48%]">
            <h2 className=" text-[#fff] mt-4 md:mt-0 font-BricolageGrotesque font-semibold text-xl md:text-[32px] md:leading-[41px]">
              Medical Scribe for{" "}
              <span className=" md:block"> Healthcare Professionals</span>
            </h2>
            <p className=" text-[#FFFFFFCC] font-Inter text-sm md:text-base font-normal mt-4">
              We understand the unique challenges faced by healthcare providers.
              That's why Dorascribe was built with input from doctors, nurses,
              and medical administrators. Our intuitive interface is easy to
              use, and our platform integrates seamlessly with popular
              electronic health record (EHR) systems, simplifying your
              documentation process.
            </p>
            <a href="https://blog.dorascribe.ai">
              <button className=" text-sm mt-6 md:mt-10 bg-[#FFFFFF] rounded-[50px] py-3 px-[18px] font-BricolageGrotesque md:text-base font-normal text-[#00AAAA] shadow shadow-[#1018280A]">
                Start now
              </button>
            </a>
          </div>
        </div>
      </div>
      {/* choice 3 */}
      <div className=" px-4 md:px-14 py-8 md:py-16">
        <div className=" w-full flex flex-col-reverse md:flex-row items-center justify-between ">
          <div className=" w-full md:w-[48%]">
            <h2 className=" text-[#272D37] mt-4 md:mt-0 font-BricolageGrotesque font-semibold text-xl md:text-[32px] md:leading-[41px]">
              Security and Confidentiality{" "}
              <span className=" md:block">You Can Trust</span>
            </h2>
            <p className=" text-[#5F6D7E] font-Inter text-sm md:text-base font-normal mt-4">
              Patient confidentiality is paramount. Dorascribe adheres to strict
              HIPAA regulations, ensuring your sensitive medical data is
              protected at all times. We employ robust data encryption protocols
              and secure servers to safeguard your information from unauthorized
              access.
            </p>

            <a href="https://blog.dorascribe.ai">
              <button className=" mt-6 md:mt-10 bg-[#00aaaa] rounded-[50px] py-3 px-[18px] font-BricolageGrotesque text-sm md:text-base font-normal text-white shadow shadow-[#1018280A]">
                Start now
              </button>
            </a>
          </div>
          <div className=" w-full md:w-[48%]">
            <img src={choice3} alt="" />
          </div>
        </div>
      </div>
      {/* How it works */}
      <div className=" md:py-16 md:px-14 px-4 py-8 w-full bg-[#00AAAA]">
        <h2 className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-white">
          How it works
        </h2>
        <p className=" text-center font-Inter text-base md:text-lg font-normal mt-3 text-[#FFFFFFCC]">
          How Dorascribe Effortlessly Transforms Your Dictations into Accurate
          Medical Documents
        </p>
        <div className=" mt-16 w-full grid grid-cols-1 lg:grid-cols-3 gap-6">
          {works.map((item, index) => (
            <div
              key={index}
              className=" bg-[#FFFFFF] rounded-[20px] p-6 md:p-8 border border-[#EAEBF0]"
            >
              <span className=" flex justify-center items-center w-10 md:w-16 h-10 md:h-16">
                <img
                  src={item.icon}
                  className=" w-8 md:w-auto"
                  alt={item.name}
                />
              </span>
              <p className=" mt-4 text-black font-BricolageGrotesque font-medium text-lg md:text-xl">
                {item.name}
              </p>
              <p className=" mt-2 font-Inter text-sm md:text-base font-normal text-[#5F6D7E]">
                {item.info}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* about us  */}
      <div className=" md:py-16 md:px-14 px-4 py-8 w-full">
        <h2 className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-black">
          About us
        </h2>
        <p className=" text-center font-Inter text-base md:text-lg font-normal mt-3 text-[#282D2D]">
          Here's a description about our AI-powered medical transcription
          platform
        </p>
        <div className=" w-full h-[300px] mt-16 md:h-[560px] bg-[#f1f1f1] rounded-[20px]">
          <iframe
            className=" w-full h-full"
            src="https://www.youtube.com/embed/io7dGWMPrHY"
          ></iframe>
        </div>
      </div>

      {/* Faq */}
      <Faq />
      {/* Footer  */}
      <Footer />
    </>
  );
};

export default Landing;
