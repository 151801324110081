import { useEffect, useState } from "react";
import close from "./assets/close cross.svg";
import load from "./assets/load.gif";

const Plan = ({ closePlanModal, profileDetails }) => {
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState(profileDetails.plan || "");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false); // Changed to boolean for simplicity
  const [doraToken, setDoraToken] = useState(null);
  const [planPriceId, setPlanPriceId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [activePeriod, setActivePeriod] = useState("Month"); // New state to track the active button
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const url = new URL(`${API_BASE_URL}api/v1/plans`);

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setPlans(data.data);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      });
  }, [closePlanModal]);

  useEffect(() => {
    const storedItem = localStorage.getItem("dora_token");
    if (storedItem) {
      setDoraToken(storedItem);
    }
  }, []);

  const handlePlanSelection = (selectedPlanName, selectedPeriodicity) => {
    const selected = plan !== selectedPlanName;
    setPlan(selected ? selectedPlanName : "");
    if (selected) {
      const selectedPlan = plans.find(
        (plan) =>
          plan.name === selectedPlanName &&
          plan.periodicity_type === selectedPeriodicity
      );
      setSelectedPlan(selectedPlan);
      setPlanPriceId(selectedPlan?.id); // Use optional chaining to prevent errors
    } else {
      setSelectedPlan(null);
    }
  };

  const handleCheckout = async () => {
    setLoading(true); // Start loading

    try {
      const checkoutResponse = await fetch(
        `${API_BASE_URL}api/v1/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${doraToken}`,
            Accept: "application/json",
          },
          body: JSON.stringify({
            plan_id: planPriceId,
          }),
        }
      );

      if (!checkoutResponse.ok) {
        setErrorMessage("Failed to create checkout session");
        console.log(checkoutResponse);
        throw new Error("Failed to create checkout session");
      }

      const checkoutData = await checkoutResponse.json();
      console.log("Checkout session created successfully:", checkoutData);

      // Redirect to the Stripe checkout URL
      window.location.href = checkoutData.data.message;
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setErrorMessage("An error occurred while creating checkout session");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Filter plans based on the activePeriod
  const filteredPlans = plans.filter(
    (item) => item.periodicity_type === activePeriod || item.name === "Free" // Show the trial plan regardless of periodicity
  );

  return (
    <>
      <div className="w-full md:w-[120%] h-full bg-[#1212128d] z-[9999] fixed top-0 md:-left-[20%] px-6 flex justify-center items-center">
        <div className="w-full flex justify-center items-center">
          <div className="md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] h-[480px] p-6 rounded-[15px] w-full md:w-[430px]">
            <div className="w-full h-full overflow-y-auto bg-white">
              <span className="flex items-center justify-between">
                <p className="text-[#272D37] font-semibold font-BricolageGrotesque text-xl">
                  Plan
                </p>
                <img
                  src={close}
                  className="cursor-pointer"
                  onClick={closePlanModal}
                  alt=""
                />
              </span>
              <p className="font-Inter text-[#5F6D7E] font-normal text-base mt-2">
                Manage your Subscription plan here.
              </p>

              <div className="p-1 rounded-[60px] bg-[#F7F7F8] border border-[#EAEBF0] mt-3 w-full flex flex-row space-x-[6px] items-center">
                <button
                  className={`w-[48%] py-3 px-8 md:px-16 rounded-[25px] shadow-sm shadow-[#1018280A] font-BricolageGrotesque font-semibold text-sm ${
                    activePeriod === "Month"
                      ? "bg-[#00aaaa] text-white"
                      : "text-[#929292]"
                  }`}
                  onClick={() => {
                    setActivePeriod("Month");
                    setPlan(""); // Clear the selected plan
                    setSelectedPlan(null); // Reset the selected plan state
                  }}
                >
                  Monthly
                </button>
                <button
                  className={`w-[48%] rounded-[25px] py-3 px-8 md:px-16 font-BricolageGrotesque font-semibold text-sm ${
                    activePeriod === "Year"
                      ? "bg-[#00aaaa] text-white"
                      : "text-[#929292]"
                  }`}
                  onClick={() => {
                    setActivePeriod("Year");
                    setPlan(""); // Clear the selected plan
                    setSelectedPlan(null); // Reset the selected plan state
                  }}
                >
                  Yearly
                </button>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-2 gap-3 mt-6">
                {filteredPlans.map((item, index) => (
                  <div
                    key={index}
                    className={`border rounded-[8px] p-3 mb-3 flex flex-row justify-between cursor-pointer ${
                      plan === item.name
                        ? "border-[#00aaaa]"
                        : "border-[#EAECF0]"
                    } ${
                      item.name === profileDetails.plan ? "bg-gray-200" : ""
                    }`}
                    onClick={() =>
                      handlePlanSelection(item.name, item.periodicity_type)
                    }
                  >
                    <span>
                      <p className="font-BricolageGrotesque text-sm font-semibold text-black">
                        {item.name}
                      </p>
                      <p className="text-[#919BA7] mt-1 font-Inter text-sm font-normal">
                        ${item.price}{" "}
                        {item.name === "Free"
                          ? `for ${item.periodicity} days Trial`
                          : `Per ${item.periodicity_type}`}
                      </p>
                    </span>
                    <div className="round">
                      <input
                        type="checkbox"
                        id={item.name}
                        readOnly
                        checked={plan === item.name}
                        disabled={item.name === profileDetails.plan}
                      />
                      <label htmlFor={item.name}></label>
                    </div>
                  </div>
                ))}
              </div>

              <button
                className={`rounded-[50px] w-full mt-4 text-base font-semibold text-white font-BricolageGrotesque py-4 ${
                  selectedPlan && selectedPlan.name === "Free"
                    ? "bg-[#B6E6E6]" // Disabled color for 'Free'
                    : "bg-[#00aaaa]" // Active color for other plans
                }`}
                onClick={handleCheckout}
                disabled={
                  !selectedPlan || selectedPlan.name === "Free" || loading
                } // Disable when plan is 'Free' or loading
              >
                {loading ? (
                  <img src={load} className="w-6 h-6 mx-auto" alt="" />
                ) : (
                  "Proceed"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plan;
