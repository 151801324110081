import { useEffect, useState } from "react";
import logo from "./assets/Logo.svg";
import shorts from "./assets/shorts.svg";
import { Link } from "react-router-dom";
import { handleNewPasswordSet } from "../../controllers/UserController";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Parse the URL to get the query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email");
    const tokenParam = urlParams.get("access_code");

    // Set the states
    setEmail(emailParam);
    setOTP(tokenParam);
  }, [password]);

  const onSuccess = (response) => {
    // if (response) navigate("/dashboard");
    console.log(response);
  };

  const handleResetClick = () => {
    if (!password) {
      setError("Password is required");
      return;
    }
    setError("");

    const userData = { email, password, otp };
    handleNewPasswordSet(userData, onSuccess);
  };

  return (
    <div className="w-full h-[100vh] flex justify-center items-center bg-[#f1f1f1] text-[#000]">
      <div className="p-6 w-full max-w-[1280px] lg:max-h-[630px] bg-[#fff] h-full flex rounded-[15px] flex-row justify-center lg:justify-between">
        <div className="w-full lg:w-[49%] relative h-full flex py-6 md:py-0 flex-col items-center justify-center">
          <Link to="/" className="cursor-pointer">
            <img src={logo} alt="Dorascribe" />
          </Link>
          <p className="mt-6 font-BricolageGrotesque font-semibold text-2xl text-black">
            New Password
          </p>
          <p className="mt-1 text-[#282D2D] font-Inter font-normal text-center text-lg">
            Please enter your new password
          </p>

          <>
            <input
              type="password"
              className="mt-3 border border-[#EAEBF0] w-full md:w-[500px] rounded-[50px] px-6 py-4 font-Inter font-normal text-sm text-[#282D2D]"
              placeholder="Enter your new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            {error && (
              <p className="mt-2 text-red-600 font-Inter font-normal text-sm">
                {error}
              </p>
            )}

            <div className=" w-full md:w-[500px] flex items-center justify-between">
              <Link className="w-[48%]" to="/login">
                <button className=" w-full rounded-[50px] bg-[#000] text-base font-semibold text-white font-BricolageGrotesque py-4 mt-6">
                  Login
                </button>
              </Link>
              <button
                className=" w-[48%] rounded-[50px] bg-[#0dabab] text-base font-semibold text-white font-BricolageGrotesque py-4 mt-6"
                onClick={handleResetClick}
              >
                Submit
              </button>
            </div>
          </>
        </div>
        <div
          style={{
            backgroundImage: `url(${shorts})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="w-full lg:w-[49%] bg-[#f1f1f1] rounded-[20px] relative hidden lg:flex flex-col justify-center"
        ></div>
      </div>
    </div>
  );
};

export default NewPassword;
