import { useState } from "react";
import logo from "./assets/Logo.svg";
import shorts from "./assets/shorts.svg";
import { Link } from "react-router-dom";

const ConfirmationPage = () => {
  return (
    <div className="w-full h-[100vh] flex justify-center items-center bg-[#f1f1f1] text-[#000]">
      <div className="p-6 w-full max-w-[1280px] lg:max-h-[630px] bg-[#fff] h-full flex rounded-[15px] flex-row justify-center lg:justify-between">
        <div className="w-full lg:w-[49%] relative h-full flex py-6 md:py-0 flex-col items-center justify-center">
          <Link to="/" className="cursor-pointer">
            <img src={logo} alt="Dorascribe" />
          </Link>
          <p className="mt-6 font-BricolageGrotesque font-semibold text-2xl text-black">
            Welcome to Dorascribe
          </p>
          <p className="mt-1 text-[#282D2D] font-Inter font-normal text-center text-lg">
            Thank you for choosing Dorascribe, your AI-powered medical
            transcription platform
          </p>

          <Link className=" w-full md:w-auto" to="/dashboard">
            <button className="w-full md:w-[500px] rounded-[50px] bg-[#0dabab] text-base font-semibold text-white font-BricolageGrotesque py-4 mt-6">
              Proceed to your Dashboard
            </button>
          </Link>
        </div>
        <div
          style={{
            backgroundImage: `url(${shorts})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="w-full lg:w-[49%] bg-[#f1f1f1] rounded-[20px] relative hidden lg:flex flex-col justify-center"
        ></div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
