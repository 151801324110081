import api from '../api';

export const handleUserRegistrationWithPassword = async (userData, onSuccess) => {
	try {
		const response = await api('post', '/auth/register', userData);
		localStorage.setItem('email', userData.email);
		onSuccess(response);
	} catch (error) {
		throw error;
	}
};

export const handleUserRegistrationWithOTP = async (userData, onSuccess) => {
	try {
		const response = await api('post', '/auth/email-otp-register', userData);
		localStorage.setItem('email', userData.email);
		onSuccess(response);
	} catch (error) {
		throw error;
	}
};

export const handleUserLoginWithPassword = async (userData, onSuccess) => {
	try {
		const response = await api('post', '/auth/login', userData);
		localStorage.setItem('email', userData.email);
		onSuccess(response);
	} catch (error) {
		throw error;
	}
};

export const handleUserLoginWithOTP = async (userData, onSuccess) => {
	try {
		const response = await api('post', '/auth/email-otp-login', userData);
		localStorage.setItem('email', userData.email);
		onSuccess(response);
	} catch (error) {
		throw error;
	}
};

export const handlePasswordReset = async (userData, onSuccess) => {
	try {
		const response = await api('post', '/auth/forgot-password-email', userData);
		localStorage.setItem('email', userData.email);
		onSuccess(response);
	} catch (error) {
		throw error;
	}
};

export const handleNewPasswordSet = async (userData, onSuccess) => {
	try {
		const response = await api('post', '/auth/password-reset-otp', userData);
		localStorage.setItem('email', userData.email);
		onSuccess(response);
	} catch (error) {
		throw error;
	}
};

export const handleVerifyMail = async (userData, onSuccess) => {
	try {
		const response = await api('post', '/auth/email-verify', userData);
		localStorage.setItem('email', userData.email);
		onSuccess(response);
	} catch (error) {
		throw error;
	}
};

export const handleGetProfile = async () => {
	try {
		const response = await api('get', '/profile');
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const handleCancelSubscription = async () => {
	try {
		await api('get', '/cancel-subscription');
	} catch (error) {
		throw error;
	}
};
