import Navbar from "../../components/navbar";
import prieHero from "./assets/pricehero.webp";
import pattern from "./assets/Background pattern decorative.png";
import Footer from "../../components/footer";
import checked from "./assets/checked.svg";
// import check from "./assets/check.svg";
// import unchecked from "./assets/unchecked.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import arrowDown from "./assets/arrow-down.svg";
import DOMPurify from "dompurify";
import right from "./assets/arrow-right.svg";
import { useGTM } from "../../utils/useGTM";

const PricingPage = () => {
  // useGTM();
  const [plans, setPlans] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [activePeriod, setActivePeriod] = useState("Month"); // New state to track the active button

  useEffect(() => {
    const url = new URL(`${API_BASE_URL}api/v1/plans`);

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setPlans(data.data);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      });
  }, []);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const url = new URL(`${API_BASE_URL}api/v1/faqs`);
        const params = {
          type: "pricing",
        };
        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key])
        );

        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
        };

        const response = await fetch(url, {
          method: "GET",
          headers,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setFaqs(data.data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);

  // console.log(faqs);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleDropDown = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Filter plans based on the activePeriod
  const filteredPlans = plans.filter(
    (item) => item.periodicity_type === activePeriod || item.name === "Free" // Show the trial plan regardless of periodicity
  );
  // console.log(plans);

  // const planTable = [
  //   {
  //     name: "Free",
  //     description:
  //       "Experience the power of Dorascribe with our free tier, perfect for individuals and small practices with occasional transcription needs.",
  //     features: {
  //       Transcriptions: "10",
  //       "Regular feature updates": true,
  //       "Advanced Data privacy": false,
  //       "Live Chat Support": false,
  //     },
  //   },
  //   {
  //     name: "Premium",
  //     description:
  //       "Unlock the full potential of Dorascribe with our Premium tier, designed for busy healthcare providers and organizations with high transcription volumes.",
  //     features: {
  //       Transcriptions: "Unlimited Transcriptions",
  //       "Regular feature updates": true,
  //       "Advanced Data privacy": true,
  //       "Live Chat Support": true,
  //     },
  //   },
  // ];

  // const featuresList = [
  //   "Transcriptions",
  //   "Regular feature updates",
  //   "Advanced Data privacy",
  //   "Live Chat Support",
  // ];

  return (
    <>
      <Navbar />
      <div className=" px-4 md:px-8 w-full mt-28">
        <div
          style={{
            backgroundImage: `url(${prieHero})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="  h-[329px] w-full flex flex-col p-6 items-center justify-center relative rounded-[20px]"
        >
          <img src={pattern} className=" absolute left-0 -top-[200px]" alt="" />
          <Link
            to="/signUp"
            className=" mt-8 z-30 md:mt-12 bg-[#db383b] rounded-[50px] py-3 px-6 flex flex-row items-center space-x-1"
          >
            <p className=" text-white font-BricolageGrotesque text-sm font-medium">
              Try For Free
            </p>
            <img src={right} alt="" />
          </Link>
          <h1 className=" font-BricolageGrotesque capitalize font-semibold text-4xl md:text-[75px] md:leading-[97px] text-center text-white">
            Our Pricing
          </h1>
          <p className=" mt-3 font-Inter text-[#f5f5f58b] text-base md:text-xl font-normal text-center lg:w-[800px]">
            Flexible pricing to meet your transcription needs.
          </p>
        </div>
      </div>
      <div className=" py-8 md:py-16 px-4 md:px-14 w-full flex justify-center items-center flex-col">
        <div className=" p-1 rounded-[60px] bg-[#F7F7F8] border border-[#EAEBF0] mt-3 w-full md:w-[500px] flex flex-row space-x-[6px] items-center">
          <button
            className={`w-[48%] py-3 px-8 md:px-16 rounded-[25px] shadow-sm shadow-[#1018280A] font-BricolageGrotesque font-semibold text-sm ${
              activePeriod === "Month"
                ? "bg-[#00aaaa] text-white"
                : "text-[#929292]"
            }`}
            onClick={() => setActivePeriod("Month")}
          >
            Monthly
          </button>
          <button
            className={`w-[48%] rounded-[25px] py-3 px-8 md:px-16 font-BricolageGrotesque font-semibold text-sm ${
              activePeriod === "Year"
                ? "bg-[#00aaaa] text-white"
                : "text-[#929292]"
            }`}
            onClick={() => setActivePeriod("Year")}
          >
            Yearly
          </button>
        </div>
        <div
          className={`mt-8 w-full grid grid-cols-1 md:grid-cols-2 ${
            filteredPlans.length === 1
              ? "lg:grid-cols-1"
              : filteredPlans.length === 2
              ? "lg:grid-cols-2"
              : filteredPlans.length === 3
              ? "lg:grid-cols-3"
              : filteredPlans.length === 4
              ? "lg:grid-cols-4"
              : filteredPlans.length === 5
              ? "lg:grid-cols-3"
              : "lg:grid-cols-3"
          } gap-6`}
        >
          {filteredPlans.map((item, index) => (
            <div
              key={index}
              className="w-full border border-[#EAEBF0] rounded-[20px] p-6 md:p-8"
            >
              <p className="text-[#272D37] font-BricolageGrotesque font-semibold text-2xl md:text-4xl">
                ${item.price}{" "}
                <span className="font-Inter text-[#5F6D7E] font-normal text-base md:text-base">
                  Per {item.periodicity_type}
                </span>
              </p>
              <p className="text-[#272D37] font-BricolageGrotesque font-medium text-base md:text-xl mt-4">
                {item.name}
              </p>
              <p className="mt-2 font-Inter font-normal text-sm md:text-base text-[#5F6D7E] border-b border-[#EAEBF0] pb-6">
                {item.description}
              </p>
              <div className="py-6 space-y-3 md:h-[180px]">
                <span className="flex flex-row items-center space-x-3">
                  <img src={checked} className=" w-6 md:w-auto" alt="" />
                  <p className="text-[#272D37] font-Inter font-normal text-sm md:text-sm">
                    {item.benefits[0]}
                  </p>
                </span>
                <span className="flex flex-row items-center space-x-3">
                  <img src={checked} className=" w-6 md:w-auto" alt="" />
                  <p className="text-[#272D37] font-Inter font-normal text-sm md:text-sm">
                    {item.benefits[1]}
                  </p>
                </span>
                {item.benefits[2] && (
                  <span className="flex flex-row items-center space-x-3">
                    <img src={checked} className=" w-6 md:w-auto" alt="" />
                    <p className="text-[#272D37] font-Inter font-normal text-sm md:text-sm">
                      {item.benefits[2]}
                    </p>
                  </span>
                )}
              </div>
              <Link to="/signUp">
                <button className="w-full py-3 bg-[#00aaaa] shadow rounded-[30px] text-center shadow-[#1018280A] text-white font-BricolageGrotesque text-sm md:text-base font-semibold">
                  Get Started
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
      {/* plans table  */}
      {/* <div className="py-8 md:py-16 px-4 md:px-14 w-full overflow-x-auto">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="py-4 px-6 text-[#272D37] font-BricolageGrotesque font-semibold md:text-left text-center text-base md:text-xl md:w-1/3">
                Features
              </th>
              {planTable.map((plan, index) => (
                <th
                  key={index}
                  className="md:py-4 py-2 px-3 md:px-6 text-[#272D37] font-BricolageGrotesque font-semibold md:text-left text-center text-base md:text-xl md:w-1/3"
                >
                  {plan.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="hidden md:table-row border-y border-[#EAEBF0]">
              <td className="border-y border-[#EAEBF0] text-left md:w-1/3"></td>
              {planTable.map((plan, index) => (
                <td
                  key={index}
                  className="py-4 md:py-10 text-[#5F6D7E] font-Inter font-normal text-sm md:text-base border-y border-[#EAEBF0] text-left md:w-1/3"
                >
                  {plan.description}
                </td>
              ))}
            </tr>
            {featuresList.map((feature, index) => (
              <tr key={index}>
                <td className="py-4 px-6 text-[#272D37] font-BricolageGrotesque font-semibold text-sm md:text-base border-y border-[#EAEBF0] text-left md:w-1/3">
                  {feature}
                </td>
                {planTable.map((plan, planIndex) => (
                  <td
                    key={planIndex}
                    className="border-y border-[#EAEBF0] text-center md:text-left text-[#272D37] font-Inter font-semibold text-sm md:text-base w-1/3"
                  >
                    {plan.features[feature] !== undefined ? (
                      typeof plan.features[feature] === "string" ? (
                        plan.features[feature]
                      ) : plan.features[feature] ? (
                        <img
                          src={check}
                          alt="Checked"
                          className="mx-auto md:mx-0"
                        />
                      ) : null
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
      <div className=" px-4 md:px-14 w-full py-8 md:py-16 flex justify-center items-center flex-col">
        <p className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-black">
          Pricing FAQs
        </p>
        <div className=" mt-16">
          {faqs.map((faq, index) => (
            <div
              key={index}
              onClick={() => toggleDropDown(index)}
              className={`border-y py-6 w-full faq border-[#EAEBF0]${
                openIndex === index ? " active" : ""
              }`}
            >
              <div className="flex flex-row w-full justify-between items-center">
                <p className="font-Inter text-[#000000] text-base md:text-lg font-normal">
                  {faq.question}
                </p>
                <img
                  src={arrowDown}
                  className={`${
                    openIndex === index ? "transform rotate-180" : ""
                  } transition-transform duration-300`}
                  alt=""
                />
              </div>
              <div className={`answer${openIndex === index ? " open" : ""}`}>
                <p
                  className="font-Inter text-[#282D2D] font-normal text-sm md:text-base mt-5 transition-all duration-500"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(faq.answer),
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PricingPage;
