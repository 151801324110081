import { useEffect, useState } from "react";
import logo from "./assets/Logo.svg";
import shorts from "./assets/shorts.svg";
import { Link, useNavigate } from "react-router-dom";

const ChoosePlan = () => {
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planPriceId, setPlanPriceId] = useState(null);
  const Navigate = useNavigate();
  const [doraToken, setDoraToken] = useState(null);
  const [profileDetails, setProfileDetails] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [errorMessage, setErrorMessage] = useState("");
  const [activePeriod, setActivePeriod] = useState("Month"); // New state to track the active button

  useEffect(() => {
    const url = new URL(`${API_BASE_URL}api/v1/plans`);

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setPlans(data.data);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      });
  }, [API_BASE_URL]);

  useEffect(() => {
    const storedItem = localStorage.getItem("dora_token");
    if (storedItem) {
      setDoraToken(storedItem);
    }
  }, []);

  useEffect(() => {
    if (doraToken) {
      const url = new URL(`${API_BASE_URL}api/v1/profile`);
      const headers = {
        Authorization: `Bearer ${doraToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      fetch(url, {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setProfileDetails(data.data || {});
          const currentPlanName = data.data?.plan;
          if (currentPlanName) {
            const matchedPlan = plans.find(
              (item) => item.name === currentPlanName
            );
            if (matchedPlan) {
              setPlan(currentPlanName);
              setSelectedPlan(matchedPlan);
              setPlanPriceId(matchedPlan.id);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching profile details:", error);
        });
    }
  }, [doraToken, plans, API_BASE_URL]);

  const handlePlanSelection = (selectedPlanName, selectedPeriodicity) => {
    const selected = plan !== selectedPlanName;
    setPlan(selected ? selectedPlanName : "");
    if (selected) {
      const selectedPlan = plans.find(
        (plan) =>
          plan.name === selectedPlanName &&
          plan.periodicity_type === selectedPeriodicity
      );
      setSelectedPlan(selectedPlan);
      setPlanPriceId(selectedPlan?.id); // Use optional chaining to prevent errors
    } else {
      setSelectedPlan(null);
    }
  };

  const handleNavigateToDashboard = () => {
    Navigate("/Welcome");
  };

  const handleCheckout = async () => {
    try {
      const checkoutResponse = await fetch(
        `${API_BASE_URL}api/v1/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${doraToken}`,
            Accept: "application/json",
          },
          body: JSON.stringify({
            plan_id: planPriceId,
          }),
        }
      );

      if (!checkoutResponse.ok) {
        setErrorMessage("Failed to create checkout session");
        console.log(checkoutResponse);
        throw new Error("Failed to create checkout session");
      }

      const checkoutData = await checkoutResponse.json();
      console.log("Checkout session created successfully:", checkoutData);

      // Redirect to the Stripe checkout URL
      window.location.href = checkoutData.data.message;
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setErrorMessage("An error occurred while creating checkout session");
    }
  };

  // Filter plans based on the activePeriod
  const filteredPlans = plans.filter(
    (item) => item.periodicity_type === activePeriod || item.name === "Free" // Show the trial plan regardless of periodicity
  );

  return (
    <div className="w-full h-[100vh] flex justify-center items-center bg-[#f1f1f1] text-[#000]">
      <div className="p-6 w-full max-w-[1280px] lg:max-h-[630px] bg-[#fff] h-full flex rounded-[15px] flex-row justify-center lg:justify-between">
        <div className="w-full lg:w-[49%] relative h-full flex py-6 md:py-0 flex-col items-center justify-center overflow-y-auto ">
          <Link to="/" className="cursor-pointer mt-20">
            <img src={logo} alt="Dorascribe" />
          </Link>
          <p className="mt-6 font-BricolageGrotesque font-semibold text-2xl text-black">
            Choose a plan to Continue
          </p>

          <div className="p-1 rounded-[60px] bg-[#F7F7F8] border border-[#EAEBF0] mt-3 w-full md:w-[500px] justify-between flex flex-row space-x-[6px] items-center">
            <button
              className={`w-[48%] py-3 px-8 md:px-16 rounded-[25px] shadow-sm shadow-[#1018280A] font-BricolageGrotesque font-semibold text-sm ${
                activePeriod === "Month"
                  ? "bg-[#00aaaa] text-white"
                  : "text-[#929292]"
              }`}
              onClick={() => {
                setActivePeriod("Month");
                setPlan(""); // Clear the selected plan
                setSelectedPlan(null); // Reset the selected plan state
              }}
            >
              Monthly
            </button>
            <button
              className={`w-[48%] rounded-[25px] py-3 px-8 md:px-16 font-BricolageGrotesque font-semibold text-sm ${
                activePeriod === "Year"
                  ? "bg-[#00aaaa] text-white"
                  : "text-[#929292]"
              }`}
              onClick={() => {
                setActivePeriod("Year");
                setPlan(""); // Clear the selected plan
                setSelectedPlan(null); // Reset the selected plan state
              }}
            >
              Yearly
            </button>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-2 gap-3 mt-6 w-full md:w-[500px]">
            {filteredPlans.map((item, index) => (
              <div
                key={index}
                className={`border rounded-[8px] p-3 mb-3 flex flex-row justify-between cursor-pointer ${
                  plan === item.name ? "border-[#00aaaa]" : "border-[#EAECF0]"
                } ${item.name === profileDetails.plan ? "bg-gray-200" : ""}`}
                onClick={() =>
                  handlePlanSelection(item.name, item.periodicity_type)
                }
              >
                <span>
                  <p className="font-BricolageGrotesque text-sm font-semibold text-black">
                    {item.name}
                  </p>
                  <p className="text-[#919BA7] mt-1 font-Inter text-sm font-normal">
                    ${item.price}{" "}
                    <span className="">Per {item.periodicity_type}</span>
                  </p>
                </span>
                <div className="round">
                  <input
                    type="checkbox"
                    id={item.name}
                    onChange={() => handlePlanSelection(item.name)}
                    checked={plan === item.name}
                  />
                  <label htmlFor={item.name}></label>
                </div>
              </div>
            ))}
          </div>

          {plan !== "Free" && (
            <p className="text-left w-full md:w-[500px] mt-3 text-xs font-medium font-Inter text-[#919BA7]">
              {errorMessage}You'll only be charged after your 7 days trial
            </p>
          )}
          <div className="flex items-center w-full md:w-[500px]">
            <div className="flex items-center w-full md:w-[500px]">
              {plan === "Free" ? (
                <button
                  onClick={handleNavigateToDashboard}
                  className="w-full mt-6 rounded-[50px] bg-[#00aaaa] text-base font-semibold text-white font-BricolageGrotesque py-4"
                >
                  Start Trial Now
                </button>
              ) : plan ? (
                <button
                  onClick={handleCheckout}
                  className="w-full mt-6 rounded-[50px] bg-[#00aaaa] text-base font-semibold text-white font-BricolageGrotesque py-4"
                >
                  Proceed to Checkout
                </button>
              ) : null}
            </div>
          </div>
          {errorMessage && (
            <p className=" text-left w-full md:w-[500px] mt-3 text-xs font-medium font-Inter text-[#919BA7]">
              {errorMessage}
            </p>
          )}
        </div>
        <div
          style={{
            backgroundImage: `url(${shorts})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="w-full lg:w-[49%] bg-[#f1f1f1] rounded-[20px] relative hidden lg:flex flex-col justify-center"
        ></div>
      </div>
    </div>
  );
};

export default ChoosePlan;
