import { useEffect, useState } from "react";
import logo from "./assets/Logo.svg";
import shorts from "./assets/shorts.svg";
import { Link } from "react-router-dom";

const PaymentFailed = () => {
  return (
    <>
      <div className="w-full h-[100vh] flex justify-center items-center bg-[#f1f1f1] text-[#000]">
        <div className="p-6 w-full max-w-[1280px] lg:max-h-[630px] bg-[#fff] h-full flex rounded-[15px] flex-row justify-center lg:justify-between">
          <div className="w-full lg:w-[49%] relative h-full flex py-6 md:py-0 flex-col items-center justify-center">
            <Link to="/" className="cursor-pointer">
              <img src={logo} alt="Dorascribe" />
            </Link>
            <p className="mt-6 font-BricolageGrotesque font-semibold text-2xl text-black">
              Payment Failed
            </p>
            <p className="mt-4 text-center text-base font-normal text-black font-Inter">
              Your payment was unsuccessful. Please try again.
            </p>

            <Link to="/dashboard">
              <button className=" rounded-[50px] mt-6 px-4 text-base font-semibold text-white font-BricolageGrotesque py-4 bg-[#00aaaa]">
                Proceed to Dashboard
              </button>
            </Link>
          </div>
          <div
            style={{
              backgroundImage: `url(${shorts})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className="w-full lg:w-[49%] bg-[#f1f1f1] rounded-[20px] relative hidden lg:flex flex-col justify-center"
          ></div>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
