import { useEffect } from "react";

export const useGTM = () => {
  useEffect(() => {
    // Add GTM script to the head
    const gtmScript = document.createElement("script");
    gtmScript.innerHTML = `
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0], 
        j = d.createElement(s), 
        dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-NW985LPF');
    `;
    document.head.appendChild(gtmScript);

    // Add GTM noscript iframe to the body
    const gtmNoscript = document.createElement("noscript");
    gtmNoscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NW985LPF"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(gtmNoscript);

    return () => {
      // Cleanup: Remove the script and noscript tag when the component unmounts
      if (gtmScript) {
        document.head.removeChild(gtmScript);
      }
      if (gtmNoscript) {
        document.body.removeChild(gtmNoscript);
      }
    };
  }, []);
};
