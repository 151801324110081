import Navbar from "../../components/navbar";
import arrowDown from "./assets/arrow-down.svg";
import prieHero from "./assets/pricehero.webp";
import pattern from "./assets/Background pattern decorative.png";
import Faq from "../../components/faq";
import Footer from "../../components/footer";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import right from "./assets/arrow-right.svg";

const Faqs = () => {
  const [faqs, setFaqs] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const url = new URL(`${API_BASE_URL}api/v1/faqs`);
        const params = {
          type: "faqs",
        };
        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key])
        );

        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
        };

        const response = await fetch(url, {
          method: "GET",
          headers,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setFaqs(data.data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);

  // console.log(faqs);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleDropDown = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <Navbar />
      <div className=" px-4 md:px-8 w-full mt-28">
        <div
          style={{
            backgroundImage: `url(${prieHero})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="  h-[329px] w-full flex flex-col p-6 items-center justify-center relative rounded-[20px]"
        >
          <img src={pattern} className=" absolute left-0 -top-[200px]" alt="" />
          <Link
            to="/signUp"
            className=" mt-8 z-30 md:mt-12 bg-[#db383b] rounded-[50px] py-3 px-6 flex flex-row items-center space-x-1"
          >
            <p className=" text-white font-BricolageGrotesque text-sm font-medium">
              Try For Free
            </p>
            <img src={right} alt="" />
          </Link>
          <h1 className=" font-BricolageGrotesque capitalize font-semibold text-4xl md:text-[75px] md:leading-[97px] text-center text-white">
            FAQs
          </h1>
          <p className=" mt-3 font-Inter text-[#f5f5f58b] text-base md:text-xl font-normal text-center lg:w-[800px]">
            Need help? Our FAQ section is here to assist
          </p>
        </div>
      </div>
      <div className=" px-4 md:px-14 w-full py-8 md:py-16 flex justify-center items-center flex-col">
        <p className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-black">
          Frequently asked questions?
        </p>
        <div className=" mt-16">
          {faqs.map((faq, index) => (
            <div
              key={index}
              onClick={() => toggleDropDown(index)}
              className={`border-y py-6 w-full faq border-[#EAEBF0]${
                openIndex === index ? " active" : ""
              }`}
            >
              <div className="flex flex-row w-full justify-between items-center">
                <p className="font-Inter text-[#000000] text-base md:text-lg font-normal">
                  {faq.question}
                </p>
                <img
                  src={arrowDown}
                  className={`${
                    openIndex === index ? "transform rotate-180" : ""
                  } transition-transform duration-300`}
                  alt=""
                />
              </div>
              <div className={`answer${openIndex === index ? " open" : ""}`}>
                <p
                  className="font-Inter text-[#282D2D] font-normal text-sm md:text-base mt-5 transition-all duration-500"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(faq.answer),
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="px-4 md:px-14 w-full py-8 md:py-16 flex justify-center items-center flex-col">
        <p className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-black">
          How-To Guides
        </p>
        <p className=" text-center font-Inter text-base md:text-lg font-normal mt-3 text-[#282D2D] lg:w-[800px]">
          Improve Efficiency with Our How-To's.
        </p>

        <div className=" mt-16 grid  grid-cols-1 md:grid-cols-3 gap-8 w-full">
          <div className=" w-full h-[300px] bg-[#f1f1f1] rounded-[20px]">
            <iframe
              className=" w-full h-full rounded-[20px]"
              src="https://www.youtube.com/embed/jRnNgcmDfHY"
            ></iframe>
          </div>

          <div className=" w-full h-[300px] bg-[#f1f1f1] rounded-[20px]">
            <iframe
              className=" w-full h-full rounded-[20px]"
              src="https://www.youtube.com/embed/DoDCFIsMTNg"
            ></iframe>
          </div>

          <div className=" w-full h-[300px] bg-[#f1f1f1] rounded-[20px]">
            <iframe
              className=" w-full h-full rounded-[20px]"
              src="https://www.youtube.com/embed/rx6SbZo5Zzk"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faqs;
