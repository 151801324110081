import api from '../api';

export const handleInitializedUserNote = async (userData, onSuccess) => {
	try {
		const response = await api('post', '/notes', userData);
		onSuccess(response.data);
	} catch (error) {
		console.log(error);
	}
};

export const handleGetApplicationSettings = async () => {
	try {
		const response = await api('get', '/application_settings');
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};

export const handleGetAssemblyTempToken = async () => {
	try {
		const response = await api('get', '/transcription-token-aai');
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};

export const handleAllNotes = async () => {
	try {
		const response = await api('get', '/notes');
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};

export const handleAllDeletedNotes = async () => {
	try {
		return await api('get', `/note-deleted`);
	} catch (error) {
		console.log(error);
	}
};

export const handleGetRestoreNote = async (noteId) => {
	try {
		return await api('get', `/note-restore/${noteId}`);
	} catch (error) {
		console.log(error);
	}
};

export const handleGetNoteById = async (noteId) => {
	try {
		return await api('get', `/notes/${noteId}`);
	} catch (error) {
		console.log(error);
	}
};

export const handleUserUpdateNote = async (noteId, noteData) => {
	try {
		return await api('put', `/notes/${noteId}`, noteData);
	} catch (error) {
		console.log(error);
	}
};

export const handleGetNoteResponseUpdate = async (noteId) => {
	try {
		return await api('get', `/note-response-update/${noteId}`);
	} catch (error) {
		console.log(error);
	}
};

export const handleNoteChange = async (noteId, noteData) => {
	try {
		noteData.note_id = noteId;
		return await api('post', `/note-template-change`, noteData);
	} catch (error) {
		console.log(error);
	}
};

export const handleNoteResponseUpdate = async (noteId, noteData) => {
	try {
		return await api('put', `/note-response-update/${noteId}`, noteData);
	} catch (error) {
		console.log(error);
	}
};

export const handleDeleteNoteById = async (noteId) => {
	try {
		return await api('delete', `/notes/${noteId}`);
	} catch (error) {
		console.log(error);
	}
};

export const handleDeleteNotePermanently = async (noteId) => {
	try {
		return await api('delete', `/note-permanent-delete/${noteId}`);
	} catch (error) {
		console.log(error);
	}
};

export const getTemplates = async () => {
	try {
		return await api('get', '/templates');
	} catch (error) {
		console.log(error);
	}
};
