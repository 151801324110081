import { useEffect, useState } from "react";
import arrowDown from "./assets/arrow-down.svg";
import DOMPurify from "dompurify";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const url = new URL(`${API_BASE_URL}api/v1/faqs`);
        const params = {
          type: "faqs",
        };
        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key])
        );

        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
        };

        const response = await fetch(url, {
          method: "GET",
          headers,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setFaqs(data.data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);

  // console.log(faqs);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleDropDown = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className=" px-4 md:px-14 w-full py-8 md:py-16 flex justify-center items-center flex-col">
        <h2 className=" text-center font-BricolageGrotesque font-semibold text-2xl md:text-[40px] text-black">
          Our Frequently asked questions?
        </h2>
        <div className=" mt-16">
          {faqs.map((faq, index) => (
            <div
              key={index}
              onClick={() => toggleDropDown(index)}
              className={`border-y py-6 w-full faq border-[#EAEBF0]${
                openIndex === index ? " active" : ""
              }`}
            >
              <div className="flex flex-row w-full justify-between items-center">
                <p className="font-Inter text-[#000000] text-base md:text-lg font-normal">
                  {faq.question}
                </p>
                <img
                  src={arrowDown}
                  className={`${
                    openIndex === index ? "transform rotate-180" : ""
                  } transition-transform duration-300`}
                  alt=""
                />
              </div>
              <div className={`answer${openIndex === index ? " open" : ""}`}>
                <p
                  className="font-Inter text-[#282D2D] font-normal text-sm md:text-base mt-5 transition-all duration-500"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(faq.answer),
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Faq;
