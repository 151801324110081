import steth from './assets/steth.svg';
// import stethAc from './assets/stethAC.svg';
import pulse from './assets/pulse.svg';
// import pulseAc from './assets/pulseAC.svg';
// import mic from './assets/Microphone.svg';
import { useContext, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ActivePageContext, SidebarContext } from '../contexts/ActivePageContext';
import UserFormModal from './UserFormModal';
import AllNotesMobile from './allNotesMobile';
import { Tooltip } from 'react-tooltip';

const useLocalStorageListener = (key, initialValue) => {
	const [value, setValue] = useState(() => {
		const storedValue = localStorage.getItem(key);
		return storedValue !== null ? JSON.parse(storedValue) : initialValue;
	});

	useEffect(() => {
		const handleStorageChange = (event) => {
			if (event.key === key) {
				setValue(event.newValue ? JSON.parse(event.newValue) : initialValue);
			}
		};

		window.addEventListener('storage', handleStorageChange);

		const originalSetItem = localStorage.setItem;
		localStorage.setItem = function (setItemKey, setItemValue) {
			originalSetItem.apply(this, arguments);
			if (setItemKey === key) {
				window.dispatchEvent(
					new StorageEvent('storage', {
						key: setItemKey,
						newValue: setItemValue,
					})
				);
			}
		};

		return () => {
			window.removeEventListener('storage', handleStorageChange);
			localStorage.setItem = originalSetItem;
		};
	}, [key, initialValue]);

	return [value, setValue];
};

const Home = () => {
	const { setActivePage } = useContext(ActivePageContext);
	const [doraToken, setDoraToken] = useState(null);
	const [userName, setUserName] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [profileDetails, setProfileDetails] = useState([]);
	const [startRecording, setStartRecording] = useState(false);
	// const [noteId, setNoteId] = useLocalStorageListener("note_id", null);
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

	const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

	// useEffect(() => {
	//   setNoteId(localStorage.getItem("note_id"));
	// }, [setNoteId]);

	const handleClick = (page) => {
		setActivePage(page);
	};

	// Check for doraToken in localStorage
	useEffect(() => {
		localStorage.removeItem('patient_info');

		const storedToken = localStorage.getItem('dora_token');
		if (storedToken) {
			setDoraToken(storedToken);
		}
	}, []);

	// Check for dora_UserName in localStorage and fetch profile if not found
	useEffect(() => {
		const storedUserName = localStorage.getItem('dora_UserName');
		if (storedUserName) {
			setUserName(storedUserName);
		} else if (doraToken) {
			const url = new URL(`${API_BASE_URL}api/v1/profile`);

			const headers = {
				Authorization: `Bearer ${doraToken}`,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			};

			fetch(url, {
				method: 'GET',
				headers,
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.data && data.data.userName) {
						setProfileDetails(data.data);
						if (data.data.userName !== storedUserName) {
							setUserName(data.data.userName);
							localStorage.setItem('dora_UserName', data.data.userName);
						}
					}
				})
				.catch((error) => {
					console.error('Error fetching profile details:', error);
				});
		}
	}, [doraToken]);

	return (
		<>
			{/* start recording modal  */}
			<UserFormModal isVisible={startRecording} onClose={() => setStartRecording(false)} handleClick={handleClick} />

			{/* allNotesMobile */}
			{isMobile ? <AllNotesMobile /> : null}

			{/* Home  */}
			<div className='absolute lg:left-[25%] top-[70px] p-6 md:px-12 md:py-8 w-full lg:w-[75%] hidden md:block'>
				{userName && (
					<p className=' gradient-text text-3xl md:text-4xl font-semibold font-BricolageGrotesque capitalize'>
						{'Hello ' + userName}
					</p>
				)}
				<p className='gradient-text1 text-3xl md:text-4xl font-semibold font-BricolageGrotesque'>
					How can I help you today?
				</p>
				<div className=' w-full mb-10'>
					<div className=' mt-8 w-full grid grid-cols-1 md:grid-cols-2 gap-6'>
						<div
							onClick={() => {
								setStartRecording(true);
							}}
							className=' md:w-full bg-[#F5F5F5] rounded-[20px] p-6 relative transition-transform'
						>
							<div className=' w-full h-full absolute top-0 left-0 gradientBG rounded-b-[20px] transition-transform'></div>
							<img src={steth} className=' w-10 h-10 md:w-16 md:h-16' alt='' />
							<p className=' mt-4  font-BricolageGrotesque text-base md:text-xl font-semibold'>Record a consultation</p>
							<p className=' mt-2 font-Inter text-sm md:text-sm md:w-auto font-normal text-[#666666]'>
								Effortlessly create comprehensive and accurate patient notes from your voice recordings or uploaded
								transcripts, saving you time and ensuring detailed documentation of every patient encounter.
							</p>
						</div>
						<div
							onClick={() => {
								setStartRecording(true);
							}}
							className=' md:w-full bg-[#F5F5F5] rounded-[20px] p-6 relative  transition-transform'
						>
							<div className=' w-full h-full absolute top-0 left-0 gradientBG rounded-b-[20px] transition-transform'></div>
							<img src={pulse} className=' w-10 h-10 md:w-16 md:h-16' alt='' />
							<p className=' mt-4 md:mt-6 font-BricolageGrotesque text-base md:text-xl font-semibold'>
								Generate a Patient Note
							</p>
							<p className=' mt-2 md:mt-3 font-Inter text-sm md:text-sm md:w-auto font-normal text-[#666666]'>
								Effortlessly generate your patient notes from audio recordings or handwritten documents, ensuring
								accurate and comprehensive documentation, while freeing up valuable time for you to focus on delivering
								exceptional patient care and improving overall efficiency in your practice.
							</p>
						</div>
					</div>
				</div>

				<div className='w-full px-4 md:px-12 py-6 md:py-8 hidden md:block'>
					<button
						onClick={() => setStartRecording(true)}
						className=' mx-auto py-3 bg-[#0DABAB] rounded-[50px] flex justify-center items-center px-6'
						data-tooltip-id='create-1'
					>
						{/* <img src={mic} className=' w-6 md:w-auto' alt='' /> */}
						<p className=' text-white font-BricolageGrotesque font-semibold text-base md:text-lg'>Create new patient</p>
					</button>
					<Tooltip
						id='create-1'
						place='top'
						variant='dark'
						content='Click to create a new patient medical note recording.'
					/>
				</div>
			</div>
		</>
	);
};

export default Home;

