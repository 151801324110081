import whiteLogo from "./assets/whitelogo.svg";
import fb from "./assets/fb.svg";
import twi from "./assets/twi.svg";
import ig from "./assets/linkedin.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* Banner */}
      <div className=" py-8 md:py-16 px-6 md:px-14 w-full bg-[#00aaaa] flex flex-col items-center justify-center">
        <h2 className=" font-semibold font-BricolageGrotesque text-xl md:text-[32px] text-center text-white">
          Do less Admin, provide more care
        </h2>
        <p className=" mt-2 text-sm md:text-base font-normal text-center text-white font-Inter">
          Dorascribe AI speech-to-text convert clinical consultations into notes
          fast and easy.
        </p>
        <Link to="/signUp">
          <button className=" mt-8 bg-[#FFFFFF] rounded-[50px] py-3 px-[18px] font-BricolageGrotesque text-sm md:text-base font-semibold text-[#00AAAA] shadow shadow-[#1018280A]">
            Get Started Now
          </button>
        </Link>
      </div>
      {/* footer  */}
      <div className=" bg-[#014343] py-8 md:py-16 px-4 md:px-14">
        <div className=" w-full flex md:flex-row flex-col space-y-6 md:space-y-0 items-center justify-between border-b border-[#EAEBF0B2] pb-8">
          <img src={whiteLogo} alt="" />

          <div className=" flex items-center space-x-12">
            <Link to="/tutorials">
              <p className=" font-Inter font-semibold text-sm md:text-base text-white">
                Dorascribe Tutorials
              </p>
            </Link>
            <Link to="/pricing">
              <p className=" font-Inter font-semibold text-sm md:text-base text-white">
                Pricing
              </p>
            </Link>
            <Link to="https://blog.dorascribe.ai">
              <p className=" font-Inter font-semibold text-sm md:text-base text-white">
                Resources
              </p>
            </Link>
          </div>
          <span className="flex items-center space-x-6">
            {/* <img src={fb} className=" w-6 md:w-auto" alt="" /> */}
            <Link to="https://x.com/DorascribeInc" target="_blank">
              <img src={twi} className=" w-6 md:w-auto" alt="" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/dorascribe-inc"
              target="_blank"
            >
              <img src={ig} className=" w-6 md:w-auto" alt="" />
            </Link>
          </span>
        </div>
        <div className=" pt-8  flex flex-col md:flex-row space-y-6 md:space-y-0 justify-between w-full items-center md:items-end">
          <div className=" space-y-4">
            <p className="text-[#FFFFFFB2] font-Inter font-normal text-sm md:text-base">
              Contact:{" "}
              <a
                href="mailto:help@dorascribe.com"
                className=" underline underline-offset-4 font-semibold"
              >
                help@dorascribe.com
              </a>
            </p>
            <p className=" text-[#FFFFFFB2] font-Inter font-normal text-sm md:text-base">
              © 2024 Dorascribe. All Rights Reserved.
            </p>
          </div>
          <span className=" flex items-center space-x-4 md:space-x-12">
            <Link to="/privacy-policy">
              <p className=" font-Inter font-semibold text-sm md:text-base text-[#FFFFFFB2]">
                Privacy Policy
              </p>
            </Link>
            <Link to="/privacy-policy">
              <p className=" font-Inter font-semibold text-sm md:text-base text-[#FFFFFFB2]">
                Terms & Conditions
              </p>
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
