import { useEffect, useState } from "react";
import close from "./assets/close cross.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ApiCredentials = ({ closeApiModal }) => {
  const [showKey, setShowKey] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const [key, setKey] = useState("");
  const [secret, setSecret] = useState("");
  const [doraToken, setDoraToken] = useState("");
  const [loading, setLoading] = useState(true);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [copied, setCopied] = useState("");

  useEffect(() => {
    const storedItem = localStorage.getItem("dora_token");
    if (storedItem) {
      setDoraToken(storedItem);
    }
  }, []);

  // Fetch key and secret when doraToken is available
  useEffect(() => {
    if (doraToken) {
      const url = new URL(`${API_BASE_URL}api/v1/client_credentials`);

      const headers = {
        Authorization: `Bearer ${doraToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      fetch(url, {
        method: "GET",
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          setKey(data.data.message.client_id || "");
          setSecret(data.data.message.client_secret || "");
          setLoading(false); // Stop loading once data is fetched
        })
        .catch((error) => {
          console.error("Error fetching credentials:", error);
        });
    }
  }, [doraToken]);

  // Function to toggle key visibility
  const toggleShowKey = () => {
    setShowKey((prevState) => !prevState);
  };

  // Function to toggle secret visibility
  const toggleShowSecret = () => {
    setShowSecret((prevState) => !prevState);
  };

  const copyToClipboard = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopied(field);
    setTimeout(() => setCopied(""), 2000); // Clear after 2 seconds
  };

  return (
    <div className="w-full md:w-[120%] h-full bg-[#1212128d] z-[99999] fixed top-0 md:-left-[20%] px-6 flex justify-center items-center">
      <div className="w-full flex justify-center items-center">
        <div className="md:ml-[20%] bg-[#FFFFFF] shadow-sm shadow-[#1018280A] border border-[#DAE0E6] p-6 rounded-[15px] w-full md:w-[500px]">
          <div className="w-full h-full overflow-y-auto bg-white">
            <span className="flex items-center justify-between">
              <p className="text-[#272D37] font-semibold font-BricolageGrotesque text-xl">
                API Credentials
              </p>
              <img
                src={close}
                className="cursor-pointer"
                onClick={closeApiModal}
                alt="close"
              />
            </span>
            <p className="font-Inter text-[#5F6D7E] font-normal text-base mt-2">
              See your API credentials here.
            </p>

            {loading ? (
              <SkeletonTheme color="#E0E0E0" highlightColor="#F5F5F5">
                <Skeleton height={40} className="my-4" />
                <Skeleton height={40} className="my-4" />
              </SkeletonTheme>
            ) : (
              <div className="w-full mt-6">
                {/* Client ID Section */}
                <label
                  htmlFor="key"
                  className="flex flex-col w-full font-BricolageGrotesque text-[#272D37] text-sm font-semibold"
                >
                  Client ID
                  <div className="relative w-full">
                    <input
                      id="key"
                      value={key}
                      type={showKey ? "text" : "password"}
                      className="w-full py-3 pl-6 pr-12 font-Inter capitalize text-[#919BA7] text-sm font-normal mt-3 border border-[#DAE0E6] rounded-[20px]"
                      readOnly
                    />
                    <button
                      onClick={() => copyToClipboard(key, "Client ID")}
                      className="absolute right-3 top-7 text-xs text-[#00AAAA]"
                    >
                      {copied === "Client ID" ? "Copied!" : "Copy"}
                    </button>
                  </div>
                </label>
                <div className="w-full mt-4">
                  <button
                    onClick={toggleShowKey}
                    className="block ml-auto bg-[#00AAAA] py-2 px-4 rounded-[30px] text-center font-semibold font-BricolageGrotesque text-xs text-white"
                  >
                    {showKey ? "Hide Key" : "Show Key"}
                  </button>
                </div>

                {/* Client Secret Section */}
                <label
                  htmlFor="secret"
                  className="flex flex-col w-full mt-3 font-BricolageGrotesque text-[#272D37] text-sm font-semibold"
                >
                  Client Secret Key
                  <div className="relative w-full">
                    <input
                      id="secret"
                      value={secret}
                      type={showSecret ? "text" : "password"}
                      className="w-full py-3 pl-6 pr-12 font-Inter capitalize text-[#919BA7] text-sm font-normal mt-3 border border-[#DAE0E6] rounded-[20px]"
                      readOnly
                    />
                    <button
                      onClick={() => copyToClipboard(secret, "Client Secret")}
                      className="absolute right-3 top-7 text-xs text-[#00AAAA]"
                    >
                      {copied === "Client Secret" ? "Copied!" : "Copy"}
                    </button>
                  </div>
                </label>
                <div className="w-full mt-4">
                  <button
                    onClick={toggleShowSecret}
                    className="block ml-auto bg-[#00AAAA] py-2 px-4 rounded-[30px] text-center font-semibold font-BricolageGrotesque text-xs text-white"
                  >
                    {showSecret ? "Hide Secret Key" : "Show Secret Key"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiCredentials;
