import React, { useEffect, useRef, useContext, useState, useCallback } from 'react';
import { RealtimeTranscriber } from 'assemblyai/streaming';
import * as RecordRTC from 'recordrtc';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import trans from './assets/tans.svg';
import stop from './assets/StopCircle.svg';
import pause from './assets/pause-white.svg';
import mic from './assets/Microphone.svg';
import trash from './assets/trash.svg';
import BackArrowSVG from './assets/back-arrow-1.svg';
import { ActivePageContext } from '../contexts/ActivePageContext';
import { handleGetProfile } from '../../../controllers/UserController';
import {
	handleGetApplicationSettings,
	handleGetAssemblyTempToken,
	handleGetNoteById,
	handleUserUpdateNote,
} from '../../../controllers/NoteController';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useLocalStorageListener from '../hooks/useLocalStorageListener';
import { Link, useNavigate } from 'react-router-dom';
import WaveAnimation from './WaveAnimation';
// import ConfirmationModal from './ConfirmationModal';

// let mediaRecorder;
let audioText;
const DG_KEY = process.env.REACT_APP_DEEPGRAM_API_KEY;
let keepAlive;
let socket;

//? FOR AZURE START
const SPEECH_KEY = process.env.REACT_APP_AZURE_SPEECH_KEY;
const SPEECH_REGION = process.env.REACT_APP_SPEAZURE_ECH_REGION;
//? FOR AZURE END

const Transcribing = () => {
	let currentText = '';

	const { setActivePage } = useContext(ActivePageContext);

	const [time, setTime] = useState(0);
	const [errMsg, setErrMsg] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [isPaused, setIsPaused] = useState(true);
	const [canResume, setCanResume] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const [transcriptionModel, setTranscriptionModel] = useState(null);
	const [isProcessing, setIsProcessing] = useState(false);
	const [noteId, setNoteId] = useLocalStorageListener('note_id', null);
	const [patientInfo, setPatientInfo] = useLocalStorageListener('patient_info', null);
	const [transcriptFinalized, setTranscriptFinalized] = useState({
		transcript: '',
	});
	const [isOnline, setIsOnline] = useState(window.navigator.onLine);
	const countRef = useRef(null);
	const contentEditableRef = useRef(null);
	const realtimeTranscriber = useRef(null);
	const recorder = useRef(null);
	const stream = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	//? FOR AZURE START
	const [partialTranscript, setPartialTranscript] = useState('');
	const [mediaRecorder, setMediaRecorder] = useState(null);
	const speechConfig = useRef(null);
	const audioConfig = useRef(null);
	const recognizer = useRef(null);
	//? FOR AZURE END
	// const [isModalVisible, setModalVisible] = useState(false);
	// const [isDisableButton, setDisableButton] = useState(false);
	const [rawTranscript, setRawTranscript] = useState('');
	const [selectedLanguage, setSelectedLanguage] = useState({ name: 'English', code: 'en-US' });
	const navigate = useNavigate();

	const fetchNoteData = useCallback(
		(id) => {
			setIsLoading(true);

			handleGetProfile().then((response) => {
				const res = response.data;
				setSelectedLanguage(res);
			});

			handleGetNoteById(id)
				.then((response) => {
					const data = response.data.data;
					const patientData = { name: data.patient_name, pronoun: data.patient_pronoun };

					if (data) setIsLoading(false);
					setPatientInfo(patientData);

					const rawTranscriptionContent = data?.raw_transcription;
					setRawTranscript(rawTranscriptionContent);
				})
				.catch((error) => {
					console.error('Error fetching note data:', error);
				});
		},
		[setPatientInfo]
	);

	useEffect(() => {
		const storedNoteId = localStorage.getItem('note_id');
		setNoteId(JSON.parse(storedNoteId));
	}, [setNoteId]);

	useEffect(() => {
		if (noteId !== null) {
			fetchNoteData(noteId);
		}
	}, [fetchNoteData, noteId]);

	useEffect(() => {
		const handleOnline = () => setIsOnline(true);
		const handleOffline = () => setIsOnline(false);

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []);

	useEffect(() => {
		let finalTrans = transcriptFinalized.transcript;
		if (finalTrans) {
			let objData = {
				action: 'raw_transcription',
				content: contentEditableRef.current ? contentEditableRef.current.innerHTML : null,
				// content: transcriptFinalized.transcript,
			};

			handleUserUpdateNote(noteId, objData);
		}
	}, [noteId, transcriptFinalized]);

	useEffect(() => {
		const storedPatientInfo = localStorage.getItem('patient_info');
		setPatientInfo(JSON.parse(storedPatientInfo));
	}, [setPatientInfo]);

	// Effect to retrieve note_id from localStorage on component mount
	useEffect(() => {
		const tempNoteId = localStorage.getItem('note_id');
		if (tempNoteId) {
			setIsLoading(false);
			setNoteId(JSON.parse(tempNoteId));
		}
	}, [setNoteId]);

	const stopStream = async () => {
		if (isActive && mediaRecorder.state !== 'inactive') {
			mediaRecorder.stop();
			mediaRecorder.stream.getTracks().forEach((track) => track.stop());
			// mediaRecorder = null;
			setMediaRecorder(null);
		}
		setIsActive(false);
	};

	// Fetch the temporary token from your local backend
	const getTemporaryToken = async () => {
		try {
			const res = await handleGetAssemblyTempToken();
			const token = res.message.token;

			return token; // Return the token
		} catch (err) {
			console.error('Error: ', err);
			return null; // Handle error and return null if there's an issue
		}
	};

	// AssemblyAI transcription implementation
	const assemblyAIAudioToTextTranscription = async () => {
		const token = await getTemporaryToken();
		if (!token) return;

		const texts = {};
		realtimeTranscriber.current = new RealtimeTranscriber({
			token,
			wordBoost: ['aws', 'azure', 'google cloud'],
			sampleRate: 16000,
			languageCode: selectedLanguage?.language_code,
			// disablePartialTranscripts: true,
		});

		realtimeTranscriber.current.on('transcript', (transcript) => {
			let msg = '';
			texts[transcript.audio_start] = transcript.text;
			const keys = Object.keys(texts).sort((a, b) => a - b);
			for (const key of keys) {
				if (texts[key]) msg += ` ${texts[key]}`;
			}
			// console.log('msg from AssemblyAI: ', msg);
			setTranscriptFinalized(() => ({ transcript: msg }));
		});

		realtimeTranscriber.current.on('error', (event) => {
			console.error(event);
			realtimeTranscriber.current.close();
			realtimeTranscriber.current = null;
		});

		realtimeTranscriber.current.on('close', (code, reason) => {
			console.log(`Connection closed: ${code} ${reason}`);
			setErrMsg(`${reason}. Kindly refresh to continue.`);
			realtimeTranscriber.current = null;
		});

		await realtimeTranscriber.current.connect();

		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then((mediaStream) => {
				stream.current = mediaStream; // Store the media stream so we can close it later
				recorder.current = new RecordRTC(mediaStream, {
					type: 'audio',
					mimeType: 'audio/webm;codecs=pcm',
					recorderType: RecordRTC.StereoAudioRecorder,
					timeSlice: 250,
					desiredSampRate: 16000,
					numberOfAudioChannels: 1,
					bufferSize: 4096,
					audioBitsPerSecond: 128000,
					ondataavailable: async (blob) => {
						if (!realtimeTranscriber.current) return;
						const buffer = await blob.arrayBuffer();
						realtimeTranscriber.current.sendAudio(buffer);
					},
				});
				recorder.current.startRecording();

				setIsRecording(true);

				countRef.current = setInterval(() => {
					setTime((prevTime) => prevTime + 1);
				}, 1000);
			})
			.catch((err) => {
				console.error(err);
				// setErrMsg(err);
			});
	};

	// Deepgram transcription implementation
	const deepgramAudioToTextTranscription = () => {
		navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
			let options = { mimeType: 'audio/webm' };

			if (!MediaRecorder.isTypeSupported(options.mimeType)) {
				if (MediaRecorder.isTypeSupported('audio/mp4')) {
					options = { mimeType: 'audio/mp4' };
				} else if (MediaRecorder.isTypeSupported('audio/wav')) {
					options = { mimeType: 'audio/wav' };
				} else {
					console.error('No supported MIME type found for MediaRecorder.');
					throw new Error('No supported MIME type found for MediaRecorder.');
				}
			}

			const recorder = new MediaRecorder(stream, options);
			setMediaRecorder(recorder);

			recorder.start(1100);

			// const socket = new WebSocket(
			// 	`wss://api.deepgram.com/v1/listen?model=nova-2-medical&language=${selectedLanguage?.language_code}&smart_format=true&endpointing=1000`,
			// 	['token', DG_KEY]
			// );
			const socket = new WebSocket(
				`wss://api.deepgram.com/v1/listen?model=nova-2&language=${selectedLanguage?.language_code}&smart_format=true&endpointing=1000`,
				['token', DG_KEY]
			);

			socket.onopen = () => {
				recorder.addEventListener('dataavailable', async (event) => {
					if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
						socket.send(event.data);
					}
				});
			};

			if (keepAlive) clearInterval(keepAlive);
			keepAlive = setInterval(() => {
				if (socket.readyState === 1) {
					const keepAliveMsg = JSON.stringify({ type: 'KeepAlive' });
					socket.send(keepAliveMsg);
				} else {
					clearInterval(keepAlive);
				}
			}, 10 * 1000);

			socket.onmessage = async (message) => {
				const received = JSON.parse(message.data);

				if (received.type === 'Results') {
					const transcript = received.channel.alternatives[0].transcript;

					if (transcript) {
						if (received.is_final) {
							currentText = currentText.concat(' ' + transcript);
							audioText = currentText;
							setTranscriptFinalized((prev) => ({
								transcript: audioText,
							}));
						} else {
							const interimText = currentText.concat(' ' + transcript);
							setTranscriptFinalized((prev) => ({
								transcript: interimText,
							}));
						}
					}
				}
			};

			socket.onclose = () => {
				clearInterval(keepAlive);
			};

			socket.onerror = (err) => {
				clearInterval(keepAlive);
				console.error('WebSocket error:', err);
			};
		});

		setIsRecording(true);
		countRef.current = setInterval(() => {
			setTime((prevTime) => prevTime + 1);
		}, 1000);
	};

	//? AZURE START
	// Azure transcription implementation
	const AzureAudioToTextTranscription = () => {
		speechConfig.current = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
		speechConfig.current.speechRecognitionLanguage = selectedLanguage?.language_code;
		audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();

		recognizer.current = new sdk.SpeechRecognizer(speechConfig.current, audioConfig.current);

		recognizer.current.recognizing = (s, e) => {
			setPartialTranscript(e.result.text);
		};

		recognizer.current.recognized = (s, e) => {
			if (e.result.text) {
				setTranscriptFinalized((prevState) => {
					const newTranscript = `${prevState.transcript} ${e.result.text.trim()}`.trim();
					return { transcript: newTranscript };
				});
			}

			setPartialTranscript('');
		};

		recognizer.current.canceled = (s, e) => {
			console.error('Recognition canceled:', e.privErrorDetails);
		};
	};
	//? AZURE END

	useEffect(() => {
		const fetchApplicationSettings = async () => {
			try {
				const resSetting = await handleGetApplicationSettings();
				// eslint-disable-next-line no-unused-vars
				const appSettings = resSetting;
				setTranscriptionModel(appSettings.transcription_model);
				// setTranscriptionModel('aai');
			} catch (error) {
				console.error('Error fetching application settings:', error);
			}
		};

		fetchApplicationSettings();
	}, []);

	const handleStart = async () => {
		setIsActive(true);
		setIsPaused(false);

		if (transcriptionModel === 'aai') {
			assemblyAIAudioToTextTranscription();
		} else if (transcriptionModel === 'dai') {
			deepgramAudioToTextTranscription();
		} else {
			AzureAudioToTextTranscription();

			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

			if (stream) {
				setIsRecording(true);
				countRef.current = setInterval(() => {
					setTime((prevTime) => prevTime + 1);
				}, 1000);
			}

			const recorder = new MediaRecorder(stream);
			setMediaRecorder(recorder);

			recognizer.current.startContinuousRecognitionAsync(() => {
				recorder.start();
				// setIsListening(true);
				// setIsActive(true);
				setIsPaused(false);
			});
		}
	};

	const handlePause = () => {
		if (mediaRecorder?.state === 'recording') {
			mediaRecorder.pause();
		} else if (recorder.current && realtimeTranscriber.current) {
			recorder.current.pauseRecording();
		} else {
			console.log('Microphone is not recording');
			return; // Exit early as there's nothing to pause
		}

		// Update state
		setIsPaused(true);
		setCanResume(true);
		setIsRecording(false);

		// Clear the timer
		clearInterval(countRef.current);
		countRef.current = null;
	};

	const handleResume = () => {
		if (mediaRecorder?.state === 'paused') {
			mediaRecorder.resume();
		} else if (recorder.current && realtimeTranscriber.current) {
			recorder.current.resumeRecording();
		} else {
			console.log('Microphone is not paused');
			return; // Exit early as there's nothing to resume
		}

		// Update state
		setCanResume(false);
		setIsRecording(true);
		setIsPaused(false);

		// Start the timer
		if (!countRef.current) {
			countRef.current = setInterval(() => {
				setTime((prevTime) => prevTime + 1);
			}, 1000);
		}
	};

	const handleStopRecording = async () => {
		handleSendToOpenAI();

		if (mediaRecorder) {
			stopStream();
		}

		if (socket) {
			socket.close();
		}

		// AssemblyAI
		if (recorder.current) {
			recorder.current.stopRecording(() => {
				recorder.current.destroy();
				recorder.current = null;
			});
		}

		// Stop the microphone (media stream)
		if (stream.current) {
			const tracks = stream.current.getTracks(); // Get all tracks (audio in this case)
			tracks.forEach((track) => track.stop()); // Stop each track
			stream.current = null; // Release the stream
		}

		if (realtimeTranscriber.current) {
			realtimeTranscriber.current.close(); // Close the transcription session
			realtimeTranscriber.current = null;
		}

		clearInterval(countRef.current);
		setTime(0);
		setIsProcessing(true);
		setIsRecording(false);
	};

	const formatTime = (time) => {
		const getSeconds = `0${time % 60}`.slice(-2);
		const minutes = Math.floor(time / 60);
		const getMinutes = `0${minutes % 60}`.slice(-2);
		const getHours = `0${Math.floor(time / 3600)}`.slice(-2);

		return `${getHours}:${getMinutes}:${getSeconds}`;
	};

	const handleSendToOpenAI = async () => {
		let dataLoader = { note_id: noteId, is_note: true };
		localStorage.setItem('note_load', JSON.stringify(dataLoader));

		try {
			let objData = {
				action: 'after_transcription',
			};

			setActivePage('Home');
			navigate('/dashboard');

			const res = await handleUserUpdateNote(noteId, objData);

			if (res && noteId) {
				setIsProcessing(false);
				dataLoader = { note_id: noteId, is_note: false };
				localStorage.setItem('note_load', JSON.stringify(dataLoader));
				// navigate('/dashboard');
				// setActivePage('Transcribed');
				// localStorage.removeItem('note_load');
			}
			//  else {
			// 	localStorage.removeItem('note_load');
			// }
		} catch (error) {
			console.error('Error sending data to server:', error);
		}
	};

	return (
		<>
			{/* <ConfirmationModal
				// isVisible={isModalVisible}
				onClose={handleCloseModal}
				onConfirm={handleConfirmAction}
				isDisableButton={isDisableButton}
				content='Are you sure you want to proceed with this action?'
			/> */}

			<div className='absolute lg:left-[25%] top-[70px] p-6 md:px-12 md:py-8 w-full lg:w-[75%]'>
				<div className='flex flex-col'>
					{isLoading ? (
						<SkeletonTheme color='#202020' highlightColor='#aaa'>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Skeleton height={25} width={150} count={1} />
							</div>
						</SkeletonTheme>
					) : (
						<span className='flex items-center justify-between'>
							<div className='flex items-center'>
								<p className='font-BricolageGrotesque text-2xl font-semibold text-[#272D37]'>{patientInfo?.name}</p>
								<span className='py-[2px] px-2 rounded-[5px] bg-[#ECFDFD] text-[#00AAAA] font-Inter text-sm font-medium ml-3'>
									{patientInfo?.pronoun}
								</span>
							</div>

							<Link
								to='/dashboard'
								className='flex py-[2px] px-2 rounded-[5px] bg-gray-200 font-Inter text-sm space-x-1'
							>
								<img src={BackArrowSVG} alt='back' /> <span>Go back</span>
							</Link>
						</span>
					)}
					{/* {isActive ? 'is active' : 'Not active'} */}
					{isProcessing ? (
						<div className='flex space-x-2 mt-3 w-[150px] items-center justify-center py-[1px] px-[10px] rounded-[20px] bg-[#ECFDFD]'>
							<button className='w-2 h-2 rounded-full bg-[#00AAAA]'></button>
							<p className='text-sm font-Inter text-[#00AAAA]'>Transcribed Note</p>
						</div>
					) : (
						<div className='flex items-center mt-3'>
							<div
								className={`flex space-x-2 md:w-[200px] items-center justify-center py-[1px] px-[10px] rounded-[20px] ${
									!isActive ? 'bg-[#FF4E3E1A]' : 'bg-[#ECFDFD]'
								}`}
							>
								<button className={`w-2 h-2 rounded-full ${!isActive ? 'bg-[#FF4E3E]' : 'bg-[#00AAAA]'}`}></button>
								<p className={`text-sm font-Inter ${!isActive ? 'text-[#FF4E3E]' : 'text-[#00AAAA]'}`}>
									Live Transcription
								</p>
							</div>

							<p
								className={`font-BricolageGrotesque font-semibold ml-2 text-base md:text-xl ${
									!isActive ? 'text-gray-500' : 'text-red-500'
								}`}
							>
								{formatTime(time)}
							</p>

							<div className='flex flex-col md:flex-row items-center ml-auto'>
								<div
									className={`h-2 w-2 md:mr-1 rounded-full animate-ping ${isOnline ? 'bg-green-500' : 'bg-red-500'}`}
								></div>
								<p className='text-xs text-gray-700'>{isOnline ? 'Online' : 'Offline'}</p>
							</div>
						</div>
					)}
					<div
						contentEditable
						ref={contentEditableRef}
						suppressContentEditableWarning={true}
						className='w-full mt-3 min-h-[150px] max-h-[240px] md:min-h-[160px] md:max-h-[500px] border border-[#EAEBF0] p-4 relative md:p-6 rounded-[10px] text-[#282D2D] font-Inter font-normal text-base md:text-base overflow-y-auto resize-none'
						style={{ height: 'auto' }}
						onInput={(e) => {
							// Adjust the height dynamically based on content
							e.target.style.height = 'auto';
							e.target.style.height = e.target.scrollHeight + 'px';
						}}
					>
						<>
							{transcriptFinalized.transcript || rawTranscript
								? (rawTranscript != null ? rawTranscript : ' ') +
								  transcriptFinalized.transcript +
								  ' ' +
								  partialTranscript
								: 'Transcription'}
						</>
					</div>

					<div className='fixed left-0 bottom-6 lg:static w-full flex justify-center items-center mt-4 md:mt-8 flex-col'>
						<>
							<div className='text-center mb-4'>
								Lang:
								<span className='py-[2px] px-2 rounded-[5px] bg-[#ECFDFD] text-[#00AAAA] font-Inter text-sm font-medium ml-3'>
									{selectedLanguage?.language}
								</span>
							</div>

							{isPaused || !isRecording ? (
								<div className='h-10 w-10 md:h-16 md:w-16'>
									<img src={trans} className='w-full' alt='' loading='lazy' />
								</div>
							) : (
								<>
									{mediaRecorder ? (
										<LiveAudioVisualizer
											mediaRecorder={mediaRecorder}
											width={100}
											height={25}
											backgroundColor='#ffffff'
											barColor='#00AAAA'
										/>
									) : (
										<WaveAnimation />
									)}
								</>
							)}
						</>
						<p className='text-[#282D2D] text-center font-Inter my-2 md:my-4 text-base italic'>
							{!isActive ? (
								<>
									Don't forget to allow Microphone <br /> access in settings
								</>
							) : (
								<>
									Press stop to
									<br /> complete transcription
								</>
							)}
						</p>
						<p className='text-[#EA4335] text-center font-Inter text-base italic'>{errMsg}</p>

						{isProcessing ? (
							<div className='w-[3506x] bg-[#EA4335] py-3 md:py-4 px-6 rounded-[50px] mt-4 md:mt-6 font-semibold font-BricolageGrotesque text-center text-base md:text-xl text-white'>
								Processing...
							</div>
						) : (
							<>
								<div className='space-x-4 flex flex-col md:flex-row items-center mt-2 md:mt-8 gap-4'>
									<button
										title='Play or Pause'
										onClick={isActive ? (isPaused ? handleResume : handlePause) : handleStart}
										className={`px-6 md:px-8 py-2 md:py-4 rounded-[50px] text-white font-semibold flex justify-center items-center ${
											isPaused ? 'bg-[#00AAAA]' : 'bg-[#333]'
										}`}
									>
										<img src={isPaused ? mic : pause} className='w-5 md:w-auto' alt={isPaused ? 'Play' : 'Pause'} />
										&nbsp;
										{isPaused && !canResume ? 'Start Recording' : canResume ? 'Resume' : 'Pause'}
									</button>
									<button
										title='Stop'
										disabled={!isActive}
										onClick={handleStopRecording}
										className={`px-6 md:px-8 py-2 md:py-4 rounded-[50px] text-white font-semibold flex items-center space-x-3 ${
											!isActive ? 'bg-[#f1b0b0]' : 'bg-[#FF4E3E]'
										}`}
									>
										<img src={stop} alt='' />
										&nbsp; Generate Transcription
									</button>
									{/* <button
									disabled={!isActive}
									onClick={handleStopRecording}
									className='bg-[#EA4335] px-6 md:px-8 py-2 md:py-4 rounded-[50px] flex items-center space-x-3'
								>
									<img src={stop} alt='' />
								</button> */}
									<button className='hidden px-6 md:px-8 py-2 md:py-4 bg-[#F5F5F5] rounded-[50px] justify-center items-center'>
										<img src={trash} className='w-5 md:w-auto' alt='' />
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Transcribing;

